<div class="lnd_header">
  <div class="lnd_header_wrapper">
    <a routerLink="/" class="lnd_header__logo">
      <img alt="logo" src="/assets/media/logo.svg" style="border-radius: 5px" />
    </a>

    <div class="lnd_header_actions">
      <kt-language-selector [isFlatList]="false"></kt-language-selector>
      <a href="https://skiplino.com/" target="_blank" class="btn about">{{ 'PAGES.LANDING.HEADER_ACTION_ABOUT' | translate }}</a>      
    </div>

    <button type="button" class="lnd_header__menu_toggle" (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="menuToggler">
      <i class="line"></i>
      <i class="line"></i>
      <i class="line"></i>
    </button>
  </div>
</div>

<div class="lnd_menu_mobile" [ngbCollapse]="isCollapsed" [@smoothCollapse]="isCollapsed ? 'initial' : 'final'">
  <div class="lnd_menu_mobile_wrapper">
    <kt-language-selector [isFlatList]="true"></kt-language-selector>
    <a href="https://skiplino.com/" target="_blank" class="btn about">{{ 'PAGES.LANDING.HEADER_ACTION_ABOUT' | translate }}</a>
  </div>
</div>