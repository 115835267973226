// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
        PHONE: 'Phone',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        MIN_MULTI_SELECT: 'Minimum',
        MAX_MULTI_SELECT: 'Maximum',
        OPTIONS: 'options',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    PAGES: {
      GENERAL: {
        BRANCHES: 'Branches',
        LOGOUT: 'Logout',
        LOGIN: 'Login',
        SEARCH_PLACEHOLDER: 'Search...',
        BOOK: 'Book',
        KM: 'KM',
        ENABLE_LOCATION_MSG: 'Please enable your location to see the branches distances.',
        ENABLE_LOCATION_MSG2: 'Make sure your location service is enabled so you can have better experience',
        BEFORE_GETTING_STARTED: 'Before we get started...',
        ENTER_EMAIL_RESET_PASSWORD: 'Enter your email to reset your password',
        ENTER_EMAIL: 'Enter your email address',
        PHONE_NUMBER_VERIFY_MSG: 'Please verify your phone number in order to continue booking',
        ENTER_YOUR: "Enter your",
        PHONE_NUMBER: 'Phone Number',
        EMAIL: 'Email',
        ENTER_PHONE_NUMBER: 'Enter your mobile number',
        EMAIL_VERIFY_MSG: 'Please verify your email in order to continue booking',
        SECURE_BOOKING_MSG: 'This will make sure your appointment is secured',
        LOGIN_BY_EMAIL: 'Login by email is available only for users who already have accounts registered to their email address.',
        LOGIN_BY_PHONE: 'Login by phone number is available only for users who already have accounts registered to their phone number.',
        USE_MOBILE: 'Use a mobile number instead',
        USE_EMAIL: 'Use your email instead',
        ALREADY_HAVE_PHONE: 'Already have an account registered with a phone?',
        ALREADY_HAVE_EMAIL: 'Already have an account registered with your email?',
        NEXT_STEP: 'Next Step',
        LATER: 'Later',
        BACK: 'Back',
        SIGNUP_AGREEMENT: 'By signing up, you\'re agreeing to our',
        TERMS_AND_CONDITIONS: 'Terms & Conditions',
        TERMS: 'terms',
        AND_SIGN: '&',
        PRIVACY_POLICY: 'privacy policy',
        RIGHTS_RESERVED: 'All Rights Reserved',
        ENTER_VALID_PHONE_MSG: 'Please enter a valid phone number',
        ENTER_VALID_EMAIL_MSG: 'Please enter a valid email address',
        CHANGE: 'Change',
        OPEN: 'OPEN',
        CLOSED: 'CLOSED',
        WORKING_HOURS: 'Working Hours',
        SHOW_MORE: 'Show More',
        SHOW_LESS: 'Show Less',
        DAY_OFF: 'Day Off',
        TODAY: 'Today',
        FROM: 'From',
        TO: 'To',
        POPULAR_TIMES: 'Popular Times',
        SUNDAY: 'Sunday',
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday',
        NO_DATA: 'No data to display!',
        SERVICES: 'Services',
        SELECTEDSERVICES: 'Selected Services',
        SERVICE: 'Service',
        QUEUES: 'Queues',
        SELECTEDQUEUES: 'Selected Queues',
        TRANSPORT: 'Transport',
        WALK: 'Walk',
        CAR: 'Car',
        TRANSIT: 'Transit',
        SELECT: 'Select',
        TIME: 'Time',
        DATE: 'Date',
        VERIFY: 'Verify',
        ATTENTION: 'Attention!',
        CLOSE: 'Close',
        TICKET: 'Ticket',
        AGENT: 'Agent',
        ENABLE: 'Enable',
        TEXT_MESSAGE: 'Text',
        SEND_SMS: 'Send SMS',
        SEND_EMAIL: 'Send Email',
        USE_EMAIL_ADDRESS: 'Use Email Address',
        USE_MOBILE_NUMBER: 'Use mobile number',
        MOBILE_NUMBER: 'Mobile number',
        PRINT: 'Print',
        OK: 'OK',
        OOPS: 'Oops!',
        CONTINUE: 'Continue',
        PURCHASE: 'Purchase',
        STAY_IN_QUEUE: 'Stay in queue',
        VIRTUAL_CALL_MSG: 'You are booking a video call service. Please download the Skiplino mobile app after booking your ticket to allow receiving the video call.',
        SKIPLINO: 'Skiplino',
        SKIPLINO_FOOTER: 'Skiplino Technologies WLL',
        NETWORK_ERR: 'Network error',
        NETWORK_ERR_MSG: 'The network is down or the location service can\'t be reached.',
        NETWORK_ERR_MSG2: 'The attempt timed out before it could get the location data.',
        GENERAL_ERROR: 'An error occurred, Please try again later.',
        ERROR_JOIN_CALL: 'An error occurred while joining the call!',
        COUNTDOWN_D: 'd',
        COUNTDOWN_H: 'h',
        COUNTDOWN_M: 'm',
        COUNTDOWN_S: 's',
        TOO_MANY_ATTEMPTS_PLEASE_TRY_AGAIN_LATER: 'Too Many Attempts, Please try again later',
        BOOK_VIA_VIRTUAL_CALL: 'Book via virtual call'
      },
      VERIFICATION: {
        ENTER_CODE: 'Enter Verification Code',
        CODE_SENT_TO: 'A verification code was sent to',
        CHECK_JUNK: 'Please check your spam/junk folder in case you didn\'t find it in your inbox.',
        VERIFICATION_CODE: 'Verification Code',
        HAVE_NOT_RECEIVE_CODE: 'Have not received any code?',
        RESEND: 'Resend',
        RESEND_CODE: 'Resend code',
        CALL: 'Call',
        CALL_MSG: 'You will receive a call with a new verification code!',
        SENT_SUCCESS: 'A new verification code has been sent successfully!',
        CANNOT_VERIFY_CODE: 'Cannot verify code. Please use one of the alternative methods or make sure to use the verification code provided.',
        CALL_ME: 'Call Me',
        SEND_WHATSAPP: 'Send to WhatsApp',
        ALREADY_REGISTERED: 'This {{alternativeMethod}} is already registered with another account.',
        ERR_CUSTOMER_NOT_FOUND: 'There is no user registered with this {{type}}',
        PHONE_NOT_SUPPORTED: 'Login with phone is no longer supported for this account. Please login with the email address linked to this account  ({{email}})',
        EMAIL_NOT_SUPPORTED: 'Login with email is no longer supported for this account. Please login with the Phone number linked to this account  ({{phone}})',
        VERIFY_WITH_YOUR: 'Verify with your',
        OTP: 'One Time Password (OTP)',
      },
      BOOKING: {
        ENABLE_LOCATION_MSG3: 'Please enable your location to see correct estimated timings.',
        ENABLE_LOCATION_MSG4: 'Please enable your location to book the ticket.',
        ENABLE_NOTIFICATION_MSG: 'Please enable your notification to receive updates on the status of your ticket.',
        SLOTS_FULLY_BOOKED: 'The Available slots are fully booked or not available!',
        TIME_BASED_ON_TIMEZONE: 'Times displayed are based on {{TimeZone}} timezone.',
        FILL_FIELDS: 'Fill the Fields',
        ENABLE_LOCATION_BOOK_SERVICE: 'Please make sure that you have enabled location service in order to book this service!',
        SERVICE_CLOSED_HOURS: 'Service is Closed, Working hours:',
        SERVICE_CLOSED_DAYS: 'Service is Closed, Working days:'
      },
      CALL: {
        INVALID_URL: 'Invalid URL',
        ENABLE_MIC: 'Please enable your browser to access your microphone then join again.',
        ENABLE_CAMERA: 'To get the full experience you need allow your browser to access your camera.',
        JOIN: 'Join',
        LEAVE: 'Leave',
        FLIP: 'Flip',
        MUTE_AUDIO: 'Mute audio',
        MUTE_VIDEO: 'Mute video',
        STAND_MSG: 'Stand in the queue for your service, even before you arrive.'
      },
      TICKET: {
        ALL_SET: 'You\'re all set!',
        DOWNLOAD_APP: 'Download the app to access, update or cancel the ticket.',
        TIME_LEFT: 'Time Left',
        STATUS: 'Status',
        BOOKED_FOR: 'Booked For',
        TICKET_SENT_TO: 'Ticket has been sent to',
        SELECT_BELOW: 'Or select from below',
        CHECK_OUT: 'Check Out',
        WAITING: 'Waiting',
        CALLED: 'Called',
        RECALLED: 'Recalled',
        DONE: 'Done',
        'NO-SHOW': 'No-show',
        CANCELLED: 'cancelled',
        CANNOT_CREATE_TICKET_NOT_AVAILABLE: 'Cannot create ticket. Selected date/time is not available.',
        CANNOT_CREATE_TICKET_HAVE_WAITING_TICKET: 'Cannot create ticket. You already have a waiting ticket in this service.',
        CANNOT_CREATE_TICKET_MAXIMUM_CANCEL: 'You reached maximum ticket cancellations.',
        CANNOT_CREATE_TICKET_CANNOT_CREATE_MAXIMUM_CANCEL: 'Cannot create ticket. You reached maximum ticket cancellations.',
        CANNOT_CREATE_TICKET_SELECTED_NOT_AVAILABLE: 'Selected date/time is not available.',
        CANNOT_CREATE_TICKET_SERVICE_CLOSED: 'Sorry, This service is closed now.',
        PEOPLE_WAITING: "People Waiting",
        ESTIMATED_WAITING: "Estimated waiting time",
        MINUTE: 'min',
        MINUTES: 'minutes',
        TICKET_SAFE: 'Keep your ticket safe!',
        YOU_WILL_BE_SERVED_SOON: 'You’ll be served soon',
        CONGRATULATIONS: 'Congratulations',
        TICKET_UPGRADED: 'Your ticket is upgraded to Fast Track',
        FAST_TRACK: 'Fast Track',
        UPGRADE: 'Upgrade',
        GET: "Get",
        SERVED_QUICKER: 'served quicker',
        FAST_TRACK_TICKET: 'a Fast Track Ticket!',
        UPGRADE_YOUR_TICKET_FOR: 'Ugrade your ticket For',
        WITH: 'With',
        WITHOUT: 'Without',
        THE_WAITING_TIME_IS_CURRENTLY: 'the waiting time is currently',
        TO_BE_SERVED: 'to be served',
        BE_THE: 'Be the',
        FIRST: '1st',
        GET_SERVED_IN: 'Get served in',
        SKIPLINO_FAST_TRACK: 'Skiplino Fast Track',
        AT_LEAST_850_FROM_BRANCH: 'You must be within at least 850 meters from the branch.',
        VERIFICATION_FAILED_PLEASE_RE_SCAN_QR_CODE: 'Verification Failed , Please re-scan the QR Code',
        INVALID_BOOK_QR_REQUEST: 'Invalid QR Booking request',
        PLEASE_ENABLE_YOUR_DEVICE_LOCATION: 'Please enable your device location',
        QR_IS_NOT_ENABLED_FOR_THIS_COMPANY: 'QR Booking is not enabled for this company',
        QR_TICKET_NOT_CREATED: 'Failed to create a ticket',
        RATE_YOUR_EXPERIENCE: 'Rate Your Experience',
        ADDITIONAL_COMMENTS: 'Additional Comments',
        OPTIONAL: 'Optional',
        THANKYOU: 'Thank you!',
        FEEDBACK_SENT: 'Your feedback has been sent',
        GET_SERVED_FASTER: 'Get served faster',
        NEXT_VISIT: 'on your next visit with',
        SKIPLINO_APP: 'Skiplino App',
        DOWNLOAD_SKIPLINO: 'DOWNLOAD SKIPLINO',
        SERVED_AT: 'Served At',
        DURATION: 'Duration',
        SUBMIT_FEEDBACK: 'SUBMIT FEEDBACK',
        DESK: 'Desk'
      },
      LANDING: {
        HEADER_ACTION_ABOUT: 'About Skiplino',

        ABOVE_UPPER_TEXT: 'Introducing',
        ABOVE_MAIN_TITLE_TOP: 'Dynamic',
        ABOVE_MAIN_TITLE_BOTTOM: 'Ticketing',
        ABOVE_SUBTITLE: 'Streamlining Walk-in <span class="line_full"></span> Experiences',

        PROCESS_TITLE: 'How It Works',
        PROCESS_1ST_STEP: 'Step 1',
        PROCESS_1ST_TITLE: 'Dynamic Ticket',
        PROCESS_1ST_DESCRIPTION: 'Scan the QR code to book the <span class="line_full"></span> needed service through your mobile.',

        PROCESS_2ND_STEP: 'Step 2',
        PROCESS_2ND_TITLE: 'Book',
        PROCESS_2ND_DESCRIPTION: 'Choose the service and enjoy <span class="line_full"></span> real time ticket updates while waiting.',

        PROCESS_3RD_STEP: 'Step 3',
        PROCESS_3RD_TITLE: 'Notified',
        PROCESS_3RD_DESCRIPTION: 'Proceed to the service when you<span class="line_full"></span> receive the ticket calling notification on your mobile.',

        PROCESS_4TH_STEP: 'Step 4',
        PROCESS_4TH_TITLE: 'Feedback',
        PROCESS_4TH_DESCRIPTION: 'Provide feedback to share <span class="line_full"></span> your experience on the service.',

        BENEFITS_TITLE: 'Benefits of <span class="line_full"></span> Dynamic Ticketing',

        GET_STARTED_TITLE: 'Ready to get started?',
        GET_STARTED_SUBTITLE: 'Create an account instantly to get started <span class="line_full"></span> or contact us to design a custom <span class="line_full"></span> package for your business.',
        GET_STARTED_ACTION_CREATE: 'Create Account',
        GET_STARTED_ACTION_CONTACT: 'Contact Sales',

      }
    }
  }
};
