<!-- begin:: Header Topbar -->
<ng-container>

    <ng-container>
        <kt-language-selector></kt-language-selector>
        <button class="btn p-0 ml-2" *ngIf="user$ | async as user">
            <div *ngIf="false" ngbDropdown class="d-inline-block" placement="bottom-right">
                <button class="btn dropdown-toggle font-weight-bolder" id="dropdownBasic1" [ngStyle]="{'color': company?.webbooking?.options?.HeaderAuthMethodColor?.text || '#212121'}"
                        ngbDropdownToggle>{{user.verificationMethod !== 'email'  ? user.mobile : user.authEmail}} &nbsp;
                    <i class="fa fas fa-angle-down icon-lg" [ngStyle]="{'color': company?.webbooking?.options?.HeaderAuthMethodColor?.icon || '#212121'}"></i></button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button  class="dropdown-item" (click)="logout()">
                      <span [inlineSVG]="'./assets/media/svg/icons/Navigation/Sign-out.svg'" class="svg-icon svg-icon-xl"></span>
                      <div class="font-weight-bold" style="line-height: 25px;">{{'PAGES.GENERAL.LOGOUT' | translate}}</div>
                    </button>

                </div>
            </div>

            <div class="d-inline-block">
                <button [matMenuTriggerFor]="userMenu" class="btn dropdown-toggle font-weight-bolder" id="dropdownBasic1" [ngStyle]="{'color': company?.webbooking?.options?.HeaderAuthMethodColor?.text || '#212121'}">
                    <!-- <i class="far fa-user-circle"></i>&nbsp; -->
                    {{user.verificationMethod !== 'email'  ? user.mobile : user.authEmail}} &nbsp;
                    <i class="fa fas fa-angle-down icon-lg" [ngStyle]="{'color': company?.webbooking?.options?.HeaderAuthMethodColor?.icon || '#212121'}"></i>
                </button>                
                <mat-menu #userMenu="matMenu">
                    <button  class="dropdown-item" (click)="logout()">
                        <span style="display: flex; align-items: center; gap: 12px;">
                            <span [inlineSVG]="'./assets/media/svg/icons/Navigation/Sign-out.svg'" class="svg-icon svg-icon-xl"></span>
                            <span class="font-weight-bold" style="line-height: 25px;">{{'PAGES.GENERAL.LOGOUT' | translate}}</span>
                        </span>
                    </button>
                </mat-menu>
            </div>

        </button>
        <button class="btn btn-hover-text-primary p-0 ml-2" routerLink="/auth" *ngIf="!(user$ | async)">
          <button type="button" class="btn btn-primary btn-sm mr-2 font-weight-boldest text-white rounded-xl">{{'PAGES.GENERAL.LOGIN' | translate}}</button>
        </button>
    </ng-container>

    <ng-container *ngIf="searchDisplay">
        <!--begin: Search -->
        <kt-search-dropdown [icon]="'./assets/media/svg/icons/General/Search.svg'" [useSVG]="true"></kt-search-dropdown>
        <!--end: Search -->
    </ng-container>

    <ng-container *ngIf="notificationsDisplay">
        <!--begin: Notifications -->
        <kt-notification [bgImage]="'./assets/media/misc/bg-1.jpg'" [pulse]="true" [skin]="'dark'"
                         [icon]="'./assets/media/svg/icons/Code/Compiling.svg'" [useSVG]="true"></kt-notification>
        <!--end: Notifications -->
    </ng-container>

    <ng-container *ngIf="quickActionsDisplay">
        <!--begin: Quick actions -->
        <kt-quick-action [bgImage]="'./assets/media/misc/bg-2.jpg'" [skin]="'dark'"
                         [icon]="'./assets/media/svg/icons/Media/Equalizer.svg'" [useSVG]="true"></kt-quick-action>
        <!--end: Quick actions -->
    </ng-container>

    <ng-container *ngIf="cartDisplay">
        <!--begin: My Cart -->
        <kt-cart [bgImage]="'./assets/media/misc/bg-1.jpg'" [icon]="'./assets/media/svg/icons/Shopping/Cart3.svg'"
                 [useSVG]="true"></kt-cart>
        <!--end: My Cart -->
    </ng-container>

    <ng-container *ngIf="quickPanelDisplay">
        <!--begin: Quick panel toggle -->
        <div class="topbar-item" ngbTooltip="Quick panel">
            <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">
                <span class="svg-icon svg-icon-xl svg-icon-primary"
                      [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-4-blocks.svg'"></span>
            </div>
        </div>
        <!--end: Quick panel toggle -->
    </ng-container>

    <ng-container *ngIf="languagesDisplay">
        <!--begin: Language bar -->
        <kt-language-selector></kt-language-selector>
        <!--end: Language bar -->
    </ng-container>

    <ng-container *ngIf="userDisplay">
        <ng-container *ngIf="userLayout === 'dropdown'">
            <kt-user-profile [userDropdownStyle]="userDropdownStyle"></kt-user-profile>
        </ng-container>
        <ng-container *ngIf="userLayout === 'offcanvas'">
            <!--begin: User bar -->
            <kt-user-profile4></kt-user-profile4>
            <!--end: User bar -->
        </ng-container>
    </ng-container>
</ng-container>
<!-- end:: Header Topbar -->
