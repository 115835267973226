<div class="d-flex flex-column-fluid flex-center mt-lg-0">
  <!--begin::Signin-->
  <div class="login-form login-signin">
    <div class="text-center mb-5 mb-lg-5">
      <ngx-ui-loader
              [fgsColor]="loaderConfiguration.fgsColor"
              [fgsPosition]="loaderConfiguration.fgsPosition"
              [fgsSize]="loaderConfiguration.fgsSize"
              [fgsType]="loaderConfiguration.fgsType"
              [bgsColor]="loaderConfiguration.bgsColor"
              [bgsOpacity]="loaderConfiguration.bgsOpacity"
              [bgsPosition]="loaderConfiguration.bgsPosition"
              [bgsSize]="loaderConfiguration.bgsSize"
              [bgsType]="loaderConfiguration.bgsType"
              [gap]="loaderConfiguration.gap"
              [logoPosition]="loaderConfiguration.logoPosition"
              [overlayColor]="loaderConfiguration.overlayColor"
              [pbColor]="loaderConfiguration.pbColor"
              [pbDirection]="loaderConfiguration.pbDirection"
              [pbThickness]="loaderConfiguration.pbThickness"
              [hasProgressBar]="loaderConfiguration.hasProgressBar"
      ></ngx-ui-loader>

     <div id="recaptcha-container" data-size="invisible"></div>

      <h3 class="font-size-h1 font-weight-boldest">{{alternativeMethod ? (alternativeMethod === 'email' ? 'PAGES.GENERAL.ENTER_EMAIL' : 'PAGES.GENERAL.ENTER_PHONE_NUMBER') : (usedLoginMethod === 'email' ? 'PAGES.GENERAL.ENTER_EMAIL' : 'PAGES.GENERAL.ENTER_PHONE_NUMBER') | translate}}</h3>
      <p class="font-weight-bold text-body" *ngIf="!alternativeMethod">
        {{'PAGES.GENERAL.SECURE_BOOKING_MSG' | translate}}
      </p>
        <p class="font-weight-bold text-body" *ngIf="alternativeMethod">
            {{alternativeMethod === 'email' ? 'PAGES.GENERAL.EMAIL_VERIFY_MSG' : 'PAGES.GENERAL.PHONE_NUMBER' | translate}}
        </p>
    </div>

    <!--begin::Form-->
    <form class="form" [formGroup]="emailForm" autocomplete="off" novalidate="novalidate" *ngIf="alternativeMethod ? alternativeMethod === 'email' : usedLoginMethod === 'email'">
      <div class="font-size-h6 text-center" *ngIf="!loginConfigurations?.email && !alternativeMethod">{{'PAGES.GENERAL.LOGIN_BY_EMAIL' | translate}}</div>
      <div class="form-group">
        <mat-form-field  [floatLabel]="'always'">
          <mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
          <input matInput
                 type="email"
                 #emailInput
                 class="font-size-h5"
                 placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
                 [autofocus]="true"
                 formControlName="email" autocomplete="off" />
          <mat-error *ngIf="isControlHasError('email','required')">
            <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email','email')">
            <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email','minlength')">
            <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email','maxlength')">
            <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
          </mat-error>
        </mat-form-field>

        <button [disabled]="(!emailForm.controls['email']['value'] || (!emailForm.valid && emailForm.controls['email'].touched)) || loading" (click)="submit()" [ngClass]="{
          'spinner spinner-right spinner-md spinner-light': loading
        }" class="btn btn-primary extra-submit">{{'PAGES.GENERAL.CONTINUE' | translate}}</button>
        <div class="or-line">{{ 'AUTH.GENERAL.OR' | translate }}</div>

        <div class="container-fluid text-center alternative-btn" *ngIf="!alternativeMethod">
          <div class="text-primary font-weight-boldest mt-2 mb-5" (click)="switchToMobile()">{{(loginConfigurations?.mobile ? 'PAGES.GENERAL.USE_MOBILE' : 'PAGES.GENERAL.ALREADY_HAVE_PHONE') | translate}}</div>
        </div>
      </div>
      <!--begin::Action-->
      <div class="form-group d-flex flex-wrap justify-content-between align-items-center form-actions">
        <a href="/#/branches" class="font-weight-bold font-size-h4">{{(alternativeMethod ? 'PAGES.GENERAL.LATER' : 'PAGES.GENERAL.BACK') | translate}}</a>
        <button [disabled]="(!emailForm.valid && emailForm.controls['email'].touched) || loading" (click)="submit()" [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }" class="btn btn-primary font-weight-bold font-size-h4 mr-2">{{'PAGES.GENERAL.NEXT_STEP' | translate}}</button>
      </div>
      <div class="mt-3 policy-wrap">
        {{'PAGES.GENERAL.SIGNUP_AGREEMENT' | translate}}
        <a class="" href="https://www.skiplino.com/terms/" target="_blank" rel="noopener noreferrer">&nbsp;{{'PAGES.GENERAL.TERMS' | translate}}&nbsp;</a>
        <a class="" href="https://www.skiplino.com/policy/" target="_blank" rel="noopener noreferrer">{{'PAGES.GENERAL.AND_SIGN' | translate}} {{'PAGES.GENERAL.PRIVACY_POLICY' | translate}}</a>
      </div>
      <!--end::Action-->
    </form>

    <!--begin::Form-->
    <form class="form" #f="ngForm" [formGroup]="loginForm" autocomplete="off" novalidate="novalidate" id="kt_login_form" *ngIf="alternativeMethod ? alternativeMethod === 'mobile' : usedLoginMethod === 'mobile'" ngxUiLoaderBlurred>
      <div class="font-size-h6 text-center" *ngIf="!loginConfigurations?.mobile && !alternativeMethod">{{'PAGES.GENERAL.LOGIN_BY_PHONE' | translate}}</div>
      <kt-auth-notice *ngIf="!f.form.valid && f.form.controls['phone'].touched"></kt-auth-notice>

      <div class="form-group">
        <!--TODO:ipapi fix-->
        <label class="intl-label">{{ 'PAGES.GENERAL.MOBILE_NUMBER' | translate }}</label>
        <ngx-intl-tel-input
                [inputId]="'adsasd'"
                [cssClass]="''"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="selectedCountry"
                [maxLength]="15"
                [tooltipField]="TooltipLabel.Name"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                name="phone"
                formControlName="phone"
        ></ngx-intl-tel-input>
        <button [disabled]="(!f.form.controls['phone']['value'] || (!f.form.valid && f.form.controls['phone'].touched)) || loading" id="kt_login_signin_submit" (click)="submit()" [ngClass]="{
          'spinner spinner-right spinner-md spinner-light': loading
        }" class="btn btn-primary extra-submit">{{'PAGES.GENERAL.CONTINUE' | translate}}</button>
        <div class="or-line">{{ 'AUTH.GENERAL.OR' | translate }}</div>
        <div class="container-fluid text-center alternative-btn" *ngIf="!alternativeMethod">
          <div class="font-weight-boldest text-body text-primary mb-5" (click)="switchToEmail()">{{(loginConfigurations?.email ? 'PAGES.GENERAL.USE_EMAIL' : 'PAGES.GENERAL.ALREADY_HAVE_EMAIL') | translate}}</div>
        </div>
      </div>

      <!--begin::Action-->
      <div class="form-group d-flex flex-wrap justify-content-between align-items-center form-actions">

        <a href="/#/branches" class="font-weight-bold font-size-h4">{{(alternativeMethod ? 'PAGES.GENERAL.LATER' : 'PAGES.GENERAL.BACK') | translate}}</a>
        <button [disabled]="(!f.form.valid && f.form.controls['phone'].touched) || loading" id="kt_login_signin_submit" (click)="submit()" [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }" class="btn btn-primary font-weight-bold font-size-h4 mr-2">{{'PAGES.GENERAL.NEXT_STEP' | translate}}</button>
      </div>

      <div class="mt-3 policy-wrap">
        {{'PAGES.GENERAL.SIGNUP_AGREEMENT' | translate}}
        <a class="" href="https://www.skiplino.com/terms/" target="_blank" rel="noopener noreferrer">&nbsp;{{'PAGES.GENERAL.TERMS' | translate}}&nbsp;</a>
        <a class="" href="https://www.skiplino.com/policy/" target="_blank" rel="noopener noreferrer">{{'PAGES.GENERAL.AND_SIGN' | translate}} {{'PAGES.GENERAL.PRIVACY_POLICY' | translate}}</a>
      </div>
      <!--end::Action-->
    </form>
    <!--end::Form-->
  </div>
  <!--end::Signin-->
</div>
<style>
  .intl-tel-input {
    width: 100%;
  }
  input[type="tel"] {
    width: inherit;
    height: 50px;
    border: 1px solid lightgray;
    border-radius: 3px;
    /*padding-left: 115px !important;*/
    font-size: 15px !important;
    color: #464E5F !important;
    /*margin-left: 140px !important;*/
    /*padding-left: 29px !important;*/
    /*background-color: #E3E5EC;*/
    /*color: white;*/
  }
</style>