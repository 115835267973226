<div class="modal-header" #popup>
    <h4 class="modal-title">Select URL</h4>
</div>
<div class="modal-body">
    <h5>
        {{options?.text}}
    </h5>
    <div class="mt-10 text-center">
        <div class="form-group">
            <form class="form" #f="ngForm" [formGroup]="urlForm" autocomplete="off" novalidate="novalidate"
                  id="kt_url_form">
                <input type="text" mat-input placeholder="Enter URL" formControlName="url" name="url"  required>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="continue()">{{options?.bookButton?.text}}</button>
</div>
