// Angular
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
// RxJS
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
// NGRX
import {select, Store} from '@ngrx/store';
// Auth reducers and selectors
import {AppState} from '../../../core/reducers/';
import {isLoggedIn} from '../_selectors/auth.selectors';
import {log} from "util";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let loggedIn_ = false;
         return this.store
            .pipe(
                select(isLoggedIn),
                map(loggedIn => {
                    loggedIn_ = loggedIn;
                    if (loggedIn_) { this.router.navigateByUrl('/');}
                    return !loggedIn_;
                })
            );
    }
}
