// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
// CRUD
import {QueryResultsModel} from '../models/query-models/query-results.model';
import {QueryParamsModel} from '../models/query-models/query-params.model';
import {HttpExtenstionsModel} from '../../crud/models/http-extentsions-model';
import {HttpConst} from "../../const/http.const";
import {Observable, of, throwError} from "rxjs";
import {switchMap} from "rxjs/operators";

@Injectable()
export class HttpUtilsService {

  constructor(private  httpClient: HttpClient) {}

  /**
   * Prepare query http params
   * @param queryParams: QueryParamsModel
   */
  getFindHTTPParams(queryParams): HttpParams {
    return new HttpParams()
      .set('lastNamefilter', queryParams.filter)
      .set('sortOrder', queryParams.sortOrder)
      .set('sortField', queryParams.sortField)
      .set('pageNumber', queryParams.pageNumber.toString())
      .set('pageSize', queryParams.pageSize.toString());
  }

  /**
   * get standard content-type
   */
  getHTTPHeaders(): HttpHeaders {
    let result = new HttpHeaders();
    result = result.set('Content-Type', 'application/json');
    return result;
  }

  baseFilter(entities: any[], queryParams: QueryParamsModel, filtrationFields: string[] = []): QueryResultsModel {
    const httpExtension = new HttpExtenstionsModel();
    return httpExtension.baseFilter(entities, queryParams, filtrationFields);
  }

  sortArray(incomingArray: any[], sortField: string = '', sortOrder: string = 'asc'): any[] {
    const httpExtension = new HttpExtenstionsModel();
    return httpExtension.sortArray(incomingArray, sortField, sortOrder);
  }

  searchInArray(incomingArray: any[], queryObj: any, filtrationFields: string[] = []): any[] {
    const httpExtension = new HttpExtenstionsModel();
    return httpExtension.searchInArray(incomingArray, queryObj, filtrationFields);
  }

  /**
   * Custom post request to check the status code.
   *
   * @param url
   * @param body
   * @param options
   */
  post(url: string, body: Object, options?: Object): Promise<any> {
    return new Promise<any>((resolve, reject) => {
       this.httpClient.post<any>(url, body, options)
          .toPromise().then((res) => {
            if (!res.statusCode || res.statusCode != HttpConst.statusCodes.OK) {    // Error
              reject(res);
            } else if (res.statusCode === HttpConst.statusCodes.OK) {               // Success
              resolve(res)
            } else if (res.statusCode === HttpConst.statusCodes.AUTH_FAIL) {         // Unauthorized access
              reject(res);
            } else {
                reject(res);
            }
          }, (error) => {
            reject(error)
          })
    });
  }

  /**
   * Custom get request to check the status code.
   *
   * @param url
   * @param options
   */
  get(url: string, options?: any, headers?): Promise<any> {
    return new Promise<any>((resolve, reject) => {
       this.httpClient.get<any>(url, {params: options, headers: headers})
          .toPromise().then((res) => {
            if (!res.statusCode || res.statusCode != HttpConst.statusCodes.OK) {    // Error
              reject(res);
            } else if (res.statusCode === HttpConst.statusCodes.OK) {               // Success
              resolve(res)
            } else if (res.statusCode === HttpConst.statusCodes.AUTH_FAIL) {         // Unauthorized access
              reject(res);
            } else {
                reject(res);
            }
          }, (error) => {
            reject(error)
          })
    });
  }



    /**
     * Custom post request to check the status code.
     *
     * @param url
     * @param body
     * @param options
     */
    postObs(url: string, body: Object, options?: Object): Observable<any> {
        // TODO: check if switchMap is required after deploying the app.
        return this.httpClient.post<any>(url, body)
            .pipe(
                switchMap((res) => {
                    if (!res.statusCode || res.statusCode != HttpConst.statusCodes.OK) {    // Error
                        return throwError(res);
                    } else if (res.statusCode === HttpConst.statusCodes.OK) {               // Success
                        return  of(res);
                    } else if(res.statusCode === HttpConst.statusCodes.AUTH_FAIL) {         // Unauthorized access
                        return throwError(res);
                    }
                })
            );
    }


    /**
     * Custom get request to check the status code.
     *
     * @param url
     * @param options
     */
    getObs(url: string, options?: any): Observable<any> {
        // TODO: check if switchMap is required after deploying the app.
        return this.httpClient.get<any>(url, {params: options})
            .pipe(
                switchMap((res) => {
                    if ( !res.statusCode || res.statusCode != HttpConst.statusCodes.OK) {   // Error
                        return throwError(res);
                    } else if (res.statusCode === HttpConst.statusCodes.OK) {               // Success
                        return  of(res);
                    } else if(res.statusCode === HttpConst.statusCodes.AUTH_FAIL) {         // Unauthorized access
                        return throwError(res);
                    } else {
                        return throwError(res);
                    }
                })
            );
    }
}
