import { Injectable } from '@angular/core';
import {HttpUtilsService} from "../..";
import {environment} from "../../../../../../environments/environment";
import {Observable} from "rxjs";
/**
 * company routes enum
 */
enum CompanyServiceRoutes {
  getByID = 'companies/get',
  getByURL = 'companies/webbooking',
  getVerificationConfigurations = 'api/verification-config'
}
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  public baseURL;

  constructor(public httpUtils: HttpUtilsService) {
    this.baseURL = environment.httpBaseURL;
  }

  /**
   * Get Company by ID.
   *
   * @param data
   */
  getByID(data: any): Observable<any>{
    return this.httpUtils.getObs(this.baseURL + CompanyServiceRoutes.getByID, data);
  }

  /**
   * Get Company by ID.
   *
   * @param data
   */
  getByURL(data: any): Observable<any>{
    return this.httpUtils.getObs(this.baseURL + CompanyServiceRoutes.getByURL, data);
  }

  // TODO: create configurations service.
  /**
   * Get ver config.
   */
  getVerificationConfigurations(branchId?, companyId?): Observable<any>{
    let data = companyId ? {companyId}: {branchId};
    return this.httpUtils.getObs(this.baseURL + CompanyServiceRoutes.getVerificationConfigurations, data);
  }

  // TODO: create customers service.
  /**
   * Get ver config.
   */
  find(params): Observable<any>{
    return this.httpUtils.getObs(this.baseURL + 'customers/find', params);
  }
}
