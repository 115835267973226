<div class="landing_page" [ngClass]="{ isRTL: currentLang?.code === 'ar' }">
  <lnd-header></lnd-header>
  <lnd-above></lnd-above>

  <section class="lnd_process">
    <h2 class="main_title">{{ 'PAGES.LANDING.PROCESS_TITLE' | translate }}</h2>
    <ul class="lnd_process_list">
      <li class="lnd_list_item lnd_animate">
        <div class="inner_text">
          <span class="above_txt">{{ 'PAGES.LANDING.PROCESS_1ST_STEP' | translate }}</span>
          <h3 class="title">{{ 'PAGES.LANDING.PROCESS_1ST_TITLE' | translate }}</h3>
          <p class="sub_title" [innerHtml]="'PAGES.LANDING.PROCESS_1ST_DESCRIPTION' | translate"></p>
        </div>
        <div class="img">
          <img src="/assets/media/landing/step1.png" alt="step1" />
        </div>
      </li>
      <li class="lnd_list_item lnd_animate">
        <div class="inner_text">
          <span class="above_txt">{{ 'PAGES.LANDING.PROCESS_2ND_STEP' | translate }}</span>
          <h3 class="title">{{ 'PAGES.LANDING.PROCESS_2ND_TITLE' | translate }}</h3>
          <p class="sub_title" [innerHtml]="'PAGES.LANDING.PROCESS_2ND_DESCRIPTION' | translate"></p>
        </div>
        <div class="img">
          <img src="/assets/media/landing/step2.png" alt="step1" />
        </div>
      </li>
      <li class="lnd_list_item lnd_animate">
        <div class="inner_text">
          <span class="above_txt">{{ 'PAGES.LANDING.PROCESS_3RD_STEP' | translate }}</span>
          <h3 class="title">{{ 'PAGES.LANDING.PROCESS_3RD_TITLE' | translate }}</h3>
          <p class="sub_title" [innerHtml]="'PAGES.LANDING.PROCESS_3RD_DESCRIPTION' | translate"></p>
        </div>
        <div class="img">
          <img src="/assets/media/landing/step3.png" alt="step1" />
        </div>
      </li>
      <li class="lnd_list_item lnd_animate">
        <div class="inner_text">
          <span class="above_txt">{{ 'PAGES.LANDING.PROCESS_4TH_STEP' | translate }}</span>
          <h3 class="title">{{ 'PAGES.LANDING.PROCESS_4TH_TITLE' | translate }}</h3>
          <p class="sub_title" [innerHtml]="'PAGES.LANDING.PROCESS_4TH_DESCRIPTION' | translate"></p>
        </div>
        <div class="img">
          <img src="/assets/media/landing/step4.png" alt="step1" />
        </div>
      </li>
    </ul>
  </section>

  <section class="lnd_benefits">
    <h2 class="main_title" [innerHtml]="'PAGES.LANDING.BENEFITS_TITLE' | translate"></h2>
    <div class="lnd_benefits_container">
      <div class="lnd_benefits_toggles">
        <div
          *ngFor="let item of benefits; let index = index"
          [ngClass]="{ active: item.active }"
          class="lnd_benefit_item"
        >
          <div class="lnd_benefit_item_header" (click)="toggleBenefit(index)">
            {{ item[currentLang?.code]?.title || item.title }}
          </div>
          <div
            class="lnd_benefit_item_body"
            [@smoothCollapse]="!item.active ? 'initial' : 'final'"
          >
            <p class="description">{{ item[currentLang?.code]?.content || item.content }}</p>
            <div class="img">
              <img [src]="item.imgUrl" alt="item.title" />
            </div>
          </div>
        </div>
      </div>
      <div class="selected_one">
        <div class="image">
          <img *ngIf="!!selectedBenifit" [@smoothImgTransition] src="{{ selectedBenifit?.imgUrl }}" alt="" />
        </div>
      </div>
    </div>
  </section>

  <section class="lnd_get_Started">
    <div class="inner_text">
      <h2 class="main_title lnd_animate">{{ 'PAGES.LANDING.GET_STARTED_TITLE' | translate }}</h2>
      <p class="sub_title lnd_animate" [innerHtml]="'PAGES.LANDING.GET_STARTED_SUBTITLE' | translate"></p>
      <div class="lnd_actions lnd_animate">
        <a href="https://portal.skiplino.com" target="_blank" class="btn create"
          >{{ 'PAGES.LANDING.GET_STARTED_ACTION_CREATE' | translate }}</a
        >
        <a
          href="https://www.skiplino.com/contact-us/"
          target="_blank"
          class="btn contact"
          >{{ 'PAGES.LANDING.GET_STARTED_ACTION_CONTACT' | translate }}</a
        >
      </div>
    </div>

    <div class="bg-img">
      <img src="/assets/media/landing/get-started-bg.png" alt="Get started" />
    </div>
    <div class="lnd_actions lnd_animate">
      <a href="https://portal.skiplino.com" target="_blank" class="btn create"
        >{{ 'PAGES.LANDING.GET_STARTED_ACTION_CREATE' | translate }}</a
      >
      <a
        href="https://www.skiplino.com/contact-us/"
        target="_blank"
        class="btn contact"
      >
      {{ 'PAGES.LANDING.GET_STARTED_ACTION_CONTACT' | translate }}
      </a>
    </div>
  </section>

  <section class="lnd_footer">
    <p class="copy_rights">
      <!-- All Rights Reserved @2024 Skiplino Technologies WLL. -->
      {{'PAGES.GENERAL.RIGHTS_RESERVED' | translate}} @ {{today | date:'yyyy'}} {{'PAGES.GENERAL.SKIPLINO_FOOTER' | translate}}.      
    </p>
  </section>
</div>
