import { Component, OnInit } from '@angular/core';
import {BranchService} from "../../../../core/_base/crud/services/branch/branch.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'kt-enable-location',
  templateUrl: './enable-location.component.html',
  styleUrls: ['./enable-location.component.scss']
})
export class EnableLocationComponent implements OnInit {
    queryParamsUrl;
  constructor(public branchService: BranchService,
              public router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
      this.queryParamsUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      this.enableLocation();
  }


  enableLocation() {
     this.branchService.getPosition().then((cords) => {
         this.router.navigateByUrl(this.queryParamsUrl); // Main page this.returnUrl
     }, (error) => {
         console.log(error);
     });
  }
}
