<div class="center">
    <div class="d-flex flex-column-fluid flex-center mt-lg-0">
        <!--begin::Signin-->
        <div class="login-form login-signin">
            <div class="text-center">
                <div class="text-center mb-5 mb-lg-15">
                    <h3 class="font-size-h1 font-weight-boldest">{{'PAGES.GENERAL.BEFORE_GETTING_STARTED' | translate}}</h3>
                    <p class="font-weight-normal mt-5">
                        {{'PAGES.GENERAL.ENABLE_LOCATION_MSG2' | translate}}
                    </p>
                </div>
<!--                <div class="mt-15" (click)="enableLocation()">-->
<!--                    <a class="btn btn-primary font-weight-bold rounded-pill">Enable Location</a>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</div>
