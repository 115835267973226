// Angular
import {Component, HostBinding, OnInit, Input, Inject} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
// RxJS
import {filter} from 'rxjs/operators';
// Translate
import {TranslationService} from '../../../../../core/_base/layout';
import {WINDOW} from "../../../../../core/_base/crud/providers/window.provider";
import {CompanyService} from "../../../../../core/_base/crud/services/company/company.service";
import {WebBookingService} from "../../../../../resolvers/webBooking/web-booking.service";

interface LanguageFlag {
    code: string;
    id: string;
    flag: string;
    name: string;
    nameInEnglish: string
}

@Component({
    selector: 'kt-language-selector',
    templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {
    // Public properties
    @Input() isFlatList: any;
    @Input() iconType: '' | 'brand';
    @Input() showSkiplinoLogo = false;

    selectedLanguage;
    isCompanyFound: boolean;
    languages: any[] = [];
    defaultLang = {
        code: "en",
        flag: "GB",
        id: "1",
        name: "English",
        nameInEnglish: "English",
        nameIn_en: "English",
        nameIn_ar: "الإنجليزية",
        nameIn_pt: "Inglês",
    };
    
    /**
     * Component constructor
     *
     * @param translationService: TranslationService
     * @param router: Router
     */
    constructor(private translationService: TranslationService,
                @Inject(WINDOW) private window: Window,
                public companyService: CompanyService,
                private router: Router,
                private webBookingService: WebBookingService) {

        const company = this.webBookingService.getCompany();
        this.isCompanyFound = !!company;
        this.languages =  company?.languages || [
            this.defaultLang, 
            {
                id: "2",
                code: "ar",
                flag: "SA",
                name: "العربية",
                nameInEnglish: "Arabic",
                nameIn_en: "Arabic",
                nameIn_ar: "العربية",
                nameIn_pt: "Árabe",
            },
            {
                id: "46",
                code: "pt",
                flag: "PT",
                name: "português",
                nameInEnglish: "Portuguese",
                nameIn_en: "Portuguese",
                nameIn_ar: "البرتغالية",
                nameIn_pt: "português",                
            },
        ];

        let primaryLanguage = this.returnlanguage(this.languages, navigator.language)
            ? this.returnlanguage(this.languages, navigator.language) : company?.primaryLanguage || this.defaultLang ;

        if (!localStorage.getItem('defaultLanguage')) {
            localStorage.setItem('defaultLanguage', JSON.stringify(primaryLanguage));
        }
        this.selectedLanguage = this.languageContainsLanguage(this.languages, JSON.parse(localStorage.getItem('defaultLanguage')))
            ? JSON.parse(localStorage.getItem('defaultLanguage')) : primaryLanguage;
        this.setLanguage(this.selectedLanguage);

        // Handle layout direction based on current language
        document.documentElement.setAttribute('dir', this.selectedLanguage.code == 'ar'? 'rtl':'ltr');
        document.documentElement.setAttribute('lang', this.selectedLanguage.code);
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        // this.setSelectedLanguage();
        // this.router.events
        //   .pipe(filter(event => event instanceof NavigationStart))
        //   .subscribe(event => {
        //     this.setSelectedLanguage();
        //   });
    }

    /**
     * Set language
     *
     * @param lang: any
     */
    setLanguage(lang) {
        this.translationService.setLanguage(lang);
    }

    /**
     * Set selected language
     */
    setSelectedLanguage(lang): any {
        this.setLanguage(lang)
        window.location.reload();
    }

    languageContainsLanguage(langs, lang): boolean {
      let found = false;
      for (let i = 0; i < langs.length; i++) {
        if(langs[i].name === lang.name) {
          found = true;
          break;
        }
      }
      return found;
    }
    returnlanguage(langs, lang): object {
        for (let i = 0; i < langs.length; i++) {
            if(langs[i].code === lang) {
                return langs[i];
            }
        }
        return;

    }
}
