// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {MatRadioModule} from "@angular/material/radio";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {BookingComponent} from "./booking/booking.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TicketComponent} from "./ticket/ticket.component";
import {InlineSVGModule} from "ng-inline-svg";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {MatTabsModule} from "@angular/material/tabs";
import {MatIconModule} from "@angular/material/icon";
import {CountdownModule} from "ngx-countdown";
import {MatCardModule} from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import {DragScrollModule} from "ngx-drag-scroll";
import {NgxQRCodeModule} from "@techiediaries/ngx-qrcode";


@NgModule({
  declarations: [BookingComponent, TicketComponent],
  exports: [],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        CoreModule,
        PartialsModule,
        MatListModule,
        MatButtonModule,
        MatRadioModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        NgxIntlTelInputModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        InlineSVGModule,
        MatCheckboxModule,
        NgbModule,
        TranslateModule,
        MatTabsModule,
        MatIconModule,
        CountdownModule,
        MatCardModule,
        MatSelectModule,
        DragScrollModule,
        NgxQRCodeModule,
    ],
  providers: []
})
export class PagesModule {
}
