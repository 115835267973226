import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import Push from 'push.js';


@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    currentFcmToken = new BehaviorSubject(null);
    constructor(private angularFireMessaging: AngularFireMessaging) {}

requestPermission() {
    Push.Permission.request(function () {
    }, function () {
    });
    this.angularFireMessaging.requestPermission.subscribe();
    this.angularFireMessaging.requestToken.subscribe(
        (token) => {
            this.currentFcmToken.next(token);
        },
        (err) => {
            console.error('Unable to get permission to notify.', err);
        }
    );
}
receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
        (payload) => {
            console.log("new message received. ", payload);
            // @ts-ignore
            if(payload?.notification) {
                // @ts-ignore
                let notificationBody=payload?.notification?.body
                // @ts-ignore
                let notificationTitle=payload?.notification?.title;

                if (Push.Permission.has()) {
                    Push.create(notificationTitle, {
                        body: notificationBody,
                        timeout: 4000,
                        silent: false,
                        onClick: function () {
                            window.focus();
                            this.close();
                        }
                    });
                } else {
                    Push.Permission.request(function () {
                    }, function () {
                    });
                }
            }
            this.currentMessage.next(payload);
        });
}
}