import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'kt-select-url-popup',
  templateUrl: './select-url-popup.component.html',
  styleUrls: ['./select-url-popup.component.scss']
})
export class SelectUrlPopupComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              private fb: FormBuilder,) { }
  @Input() options;
  urlForm: FormGroup;
  url;
  ngOnInit(): void {
    this.initForm();
  }

  close() {
    this.activeModal.dismiss();
  }

  continue() {
    this.url = this.urlForm.controls;
    this.activeModal.close( this.url.url.value);
  }

  initForm() {
    this.urlForm = this.fb.group({
      url: new FormControl(undefined, [Validators.required]),
    });
  }

}
