// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  httpBaseURL:'https://the-one-with-the-video-be.azurewebsites.net/', // https://api.skiplino.com/ //104.42.195.153:3452 //https://the-one-with-the-video-be.azurewebsites.net
  websocketBaseURL: 'wss://the-one-with-the-ws.azurewebsites.net',
  name:'Development Server',
  dbVersion: '2.15.8',
  firebaseConfig: {
    apiKey: "AIzaSyA2wYLNq7GjOqXuv6XSgwb523hUhu2aoJI",
    authDomain: "skiplino-1fc67.firebaseapp.com",
    databaseURL: "https://skiplino-1fc67.firebaseio.com",
    projectId: "skiplino-1fc67",
    storageBucket: "skiplino-1fc67.appspot.com",
    messagingSenderId: "937042024832",
    appId: "1:937042024832:web:7f67db85b74741d0500965",
    measurementId: "G-T3LF8KLPN2",
    vapidKey: "BDeUK3E-EQQKT5eUw3UGEMvLQbsMjncO-bNgjUsy0F6bE76-kBYr9ZjQNn90LMceGgl_c3CMsBxnFNkcwgYlaSc"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
