import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PartialsModule } from "../../partials/partials.module";
import { CoreModule } from "src/app/core/core.module";
import { LandingComponent } from "./landing.component";
import { NgbAccordionModule, NgbCollapseModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { lndHeaderComponent } from "./lnd-header/lnd-header.component";
import { lndAboveComponent } from "./lnd-above/lnd-above.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    declarations: [LandingComponent, lndHeaderComponent, lndAboveComponent],
      imports: [
          CommonModule,
          RouterModule.forChild([{
            path: '**', component: LandingComponent
          }]),
          PartialsModule,
          CoreModule,
          NgbModule,
          NgbAccordionModule,
          NgbCollapseModule,
          TranslateModule,
      ],
      entryComponents: [LandingComponent]
  })
  export class LandingModule { }