import {Inject, Injectable} from "@angular/core";
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CompanyService} from "../../_base/crud/services/company/company.service";
import {WINDOW} from "../../_base/crud/providers/window.provider";
import {WebBookingService} from "../../../resolvers/webBooking/web-booking.service";
import {currentUser, isLoggedIn} from "../_selectors/auth.selectors";

@Injectable()
export class BookingGuard implements CanActivate {
    constructor(public webBookingService: WebBookingService, private store: Store<AppState>, private router: Router, public companyService: CompanyService, @Inject(WINDOW) private window: Window) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let loggedIn_ = false;
        return this.store
            .pipe(
                select(isLoggedIn),
                map(loggedIn => {
                    loggedIn_ = loggedIn;
                    if (!loggedIn_) {
                        this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});
                    } else {
                        let branchId = route.params.branchId;
                        // user logged in. check against company
                        this.companyService.getVerificationConfigurations(branchId, null).toPromise().then((res) => {
                            this.store.pipe(select(currentUser)).subscribe((cu) => {
                                if(cu) {
                                    // console.log(cu)
                                    let verificationConfig = res.data?.company;
                                    // mobile is required
                                    if(verificationConfig.mobile  && !verificationConfig.email && !cu?.verified) {
                                        this.router.navigate(['auth/login/mobile'],{queryParams: {returnUrl: state.url}});
                                        return false;
                                    }

                                    //email is required
                                    else if (verificationConfig.email && !verificationConfig.mobile  && !cu?.emailVerified) {
                                        this.router.navigate(['auth/login/email'],{queryParams: {returnUrl: state.url}});
                                        return false
                                    }
                                }
                            });
                        });
                    }
                    return loggedIn_;
                })
            );
    }
}
