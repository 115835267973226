<div class="center">
    <div class="d-flex flex-column-fluid flex-center mt-lg-0">
        <!--begin::Signin-->
        <div class="login-form login-signin w-100">
            <div class="text-center mb-10 mb-lg-20">
                <h3 class="font-size-h1">{{'PAGES.VERIFICATION.ENTER_CODE' | translate}}</h3>
                <p class="font-weight-bold" *ngIf="!isEmail(mobileNumber)">
                    <span>{{'PAGES.VERIFICATION.CODE_SENT_TO' | translate}}</span> 
                    <span class="ewa-newline">
                        <span [dir]="'ltr'" class="font-weight-boldest"> {{mobileNumber}} &nbsp;</span> <span (click)="changeAuthParam()" class="btn-link">{{'PAGES.GENERAL.CHANGE' | translate}}</span>
                    </span>
                </p>
                <p class="font-weight-bold"  *ngIf="isEmail(mobileNumber)">
                    <span>{{'PAGES.VERIFICATION.CODE_SENT_TO' | translate}}</span> 
                    <span class="ewa-newline">
                        <span [dir]="'ltr'" class="font-weight-boldest"> {{mobileNumber}} &nbsp;</span>. {{'PAGES.VERIFICATION.CHECK_JUNK' | translate}} <span (click)="changeAuthParam()" class="btn-link">{{'PAGES.GENERAL.CHANGE' | translate}}</span>
                    </span>
                </p>
            </div>

            <kt-auth-notice></kt-auth-notice>

            <div class="text-center mb-10">
                <div class="font-size-h5 mb-2 ewa-hide">
                    {{'PAGES.VERIFICATION.VERIFICATION_CODE' | translate}}
                </div>
                <ngx-split-input
                        (completed)="handleCompleted($event)"
                        style="padding-right: 0px !important;"
                        [ngClass]="{'spinner spinner-center spinner-md spinner-primary': loading}">
                    <input ngxSplitInput class="verification-input text-center text-black-50 font-size-h1" [disabled]="loading" maxlength="1">
                    <input ngxSplitInput class="verification-input text-center text-black-50 font-size-h1" [disabled]="loading" maxlength="1">
                    <input ngxSplitInput class="verification-input text-center text-black-50 font-size-h1" [disabled]="loading" maxlength="1">
                    <input ngxSplitInput class="verification-input text-center text-black-50 font-size-h1" [disabled]="loading" maxlength="1">
                    <input ngxSplitInput class="verification-input text-center text-black-50 font-size-h1" [disabled]="loading" maxlength="1">
                    <input ngxSplitInput class="verification-input text-center text-black-50 font-size-h1" [disabled]="loading" maxlength="1">
                </ngx-split-input>
            </div>

            <h4 class="text-center text-primary" style="font-size:14px;" (click)="showResendSms = true">{{'PAGES.VERIFICATION.HAVE_NOT_RECEIVE_CODE' | translate}}</h4>

            <div *ngIf="!isEmail(mobileNumber)" class="example example-basic">
                <!--begin::Timeline-->
                <div class="timeline timeline-1">
                    <div class="bg-primary-opacity-20"></div>

                    <div [hidden]="!showResendSms || !_altMethodsQueue[0]" class="separator separator-solid my-6"></div>
                    <div class="timeline-item" [hidden]="!showResendSms || !_altMethodsQueue[0]">
                        <div class="timeline-badge border-0" style="background-color: transparent">
                            <i class="flaticon2-sms text-danger"></i>
                        </div>
                        <div class="timeline-content font-weight-normal">
                            {{_altMethodsQueue[0]?.shortcut}}
                        </div>
                        <span class="font-weight-bold mr-9" [hidden]="msgCountEnd || disableAfterClickMsg"><countdown
                            #cdmsg [config]="configMsg"
                            (event)="handleEventMsg($event)"></countdown></span>
                        <button [disabled]="disableAfterClickMsg"
                                [hidden]="!(msgCountEnd || disableAfterClickMsg)"
                                class="btn btn-primary font-weight-bold rounded-pill mr-2"
                                style="width: 71px"
                                (click)="updateVerification(_altMethodsQueue[0]?.method)">{{'PAGES.VERIFICATION.RESEND' | translate}}
                        </button>
                    </div>

                    <div [hidden]="!showCall || !_altMethodsQueue[1]" class="separator separator-solid my-6"></div>
                    <div class="timeline-item" [hidden]="!showCall || !_altMethodsQueue[1]">
                        <div class="timeline-badge border-0" style="background-color: transparent">
                            <i class="flaticon2-phone"></i>
                        </div>
                        <div class="timeline-content font-weight-normal">
                            {{_altMethodsQueue[1]?.shortcut}}
                        </div>
                        <div class="font-weight-bold mr-9" [hidden]="callCountEnd || disableAfterClickCall">
                            <countdown #cdcall [config]="configCall"
                                       (event)="handleEventCall($event)"></countdown>
                        </div>
                        <button [disabled]="disableAfterClickCall"
                                [hidden]="!(callCountEnd || disableAfterClickCall)"
                                class="btn btn-primary font-weight-bold rounded-pill mr-2"
                                style="width: 71px"
                                (click)="updateVerification(_altMethodsQueue[1]?.method)">{{'PAGES.VERIFICATION.CALL' | translate}}
                        </button>
                    </div>

                    <div [hidden]="!showChat || !_altMethodsQueue[2]" class="separator separator-solid my-6"></div>
                    <div [hidden]="!showChat || !_altMethodsQueue[2]" class="timeline-item">
                        <div class="timeline-badge border-0" style="background-color: transparent">
                            <i class="flaticon2-chat"></i>
                        </div>
                        <div class="timeline-content font-weight-normal">
                            {{_altMethodsQueue[2]?.shortcut}}
                        </div>
                        <span class="font-weight-bold mr-9" [hidden]="chatCountEnd"><countdown #cdchat
                                                                                               [config]="configChat"
                                                                                               (event)="handleEventChat($event)"></countdown></span>
                        <br><Br>
                        <button [hidden]="!chatCountEnd"
                                [disabled]="disableAfterClickChat"
                                style="width: 71px"
                                class="btn btn-primary rounded-pill font-weight-bold mr-2"
                                (click)="updateVerification(_altMethodsQueue[2]?.method)">{{'PAGES.VERIFICATION.RESEND' | translate}}
                        </button>

                    </div>
                </div>
                <!--end::Timeline-->
            </div>

            <div *ngIf="isEmail(mobileNumber)" class="example example-basic">
                <!--begin::Timeline-->
                <div class="timeline timeline-1">
                    <div class="bg-primary-opacity-20"></div>

                    <div [hidden]="!showResendSms || !_altMethodsQueue[0]" class="separator separator-solid my-6"></div>
                    <div class="timeline-item" [hidden]="!showResendSms || !_altMethodsQueue[0]">
                        <div class="timeline-badge border-0" style="background-color: transparent">
                            <i class="flaticon2-sms text-danger}}"></i>
                        </div>
                        <div class="timeline-content font-weight-normal">
                            {{'PAGES.VERIFICATION.RESEND_CODE' | translate}}
                        </div>
                        <span class="font-weight-bold mr-9" [hidden]="msgCountEnd || disableAfterClickMsg"><countdown
                            #cdmsg [config]="configMsg"
                            (event)="handleEventMsg($event)"></countdown></span>
                        <button [disabled]="disableAfterClickMsg"
                                [hidden]="!(msgCountEnd || disableAfterClickMsg)"
                                class="btn btn-primary font-weight-bold rounded-pill mr-2"
                                style="width: 71px"
                                (click)="updateVerification('email')">{{'PAGES.VERIFICATION.RESEND' | translate}}
                        </button>
                    </div>
                </div>
            </div>
            <!--end::Form-->
        </div>
        <!--end::Signin-->
    </div>
</div>
<style>
    .verification-input {
        /*height: 45px;*/
        width: 12%;
        background-color: white;
        border: solid 2px lightgrey;
        padding: 5px;
        margin: 2px;
        border-radius: 5px;
    }

    body > [uib-modal-window]:not([modal-render]) {
        display: none;
    }

     [uib-modal-window]:not([modal-render]) {
        display: none;
    }
</style>
