export class HttpConst {
    public static statusCodes = {
        // Status Codes
        OK: 200,
        ALMOST_OK: 201,// used for mobile authentication
        INVALID_REQUEST: 400,
        AUTH_FAIL: 401,
        CANNOT_FIND: 402,
        CANNOT_CREATE: 403,
        CANNOT_UPDATE: 404,
        CANNOT_DELETE: 405,
        CANNOT_SAVE: 406,// save = upsert (create or update)
        CANNOT_SEND: 407,
        CANNOT_DUPLICATE: 408,
        CANNOT_VERIFY: 409,
    }
}
