// Angular
import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
// RxJS

import {Subject} from 'rxjs';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Store
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
import {AuthNoticeService, AuthService} from '../../../../core/auth';
// Services
import {CompanyService} from "../../../../core/_base/crud/services/company/company.service";
// 3rd party
import {CountryISO, SearchCountryField, TooltipLabel} from 'ngx-intl-tel-input';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
// Components
import {PrebookingPopupComponent} from "../../../partials/content/skiplino/prebooking-popup/prebooking-popup.component";
import {WINDOW} from "../../../../core/_base/crud/providers/window.provider";
import {WebBookingService} from "../../../../resolvers/webBooking/web-booking.service";

declare var grecaptcha: any; // Declare grecaptcha

@Component({
    selector: 'kt-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
    // Public params
    //Forms
    loginForm: FormGroup;
    emailForm: FormGroup;
    // View logic
    loading = false;
    usedLoginMethod = '';
    loginConfigurations;
    unsubscribe: Subject<any>;
    @ViewChild('emailInput', {static: false}) private emailInput;
    // Intel tel
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    selectedCountry: CountryISO;
    captchaContainer;

    public loaderConfiguration = {
        "bgsColor": "#5867dd",
        "bgsOpacity": 0.5,
        "bgsPosition": "center-center",
        "blur": 2,
        "bgsSize": 0,
        "bgsType": "double-bounce",
        "delay": 0,
        "fastFadeOut": false,
        "fgsColor": "#5867dd",
        "fgsPosition": "center-center",
        "fgsSize": 30,
        "fgsType": "double-bounce",
        "gap": 24,
        "logoPosition": "center-center",
        "masterLoaderId": "master",
        "overlayBorderRadius": "10",
        "overlayColor": "rgba(242,243,248, 0.6)",
        "pbColor": "#5867dd",
        "pbDirection": "ltr",
        "pbThickness": 3,
        "hasProgressBar": false
    };
    countryCode;
    queryParamsUrl;
    alternativeMethod;
    recaptchaToken;
    widget_id;

    /**
     * Component constructor
     *
     * @param router
     * @param auth
     * @param authNoticeService
     * @param translate
     * @param store
     * @param fb
     * @param cdr
     * @param route
     * @param modalService
     * @param companyService
     * @param ngxLoader
     */
    constructor(
        private router: Router,
        private auth: AuthService,
        private authNoticeService: AuthNoticeService,
        private translate: TranslateService,
        private store: Store<AppState>,
        private fb: FormBuilder,
        private webBookingService: WebBookingService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        public companyService: CompanyService,
        private ngxLoader: NgxUiLoaderService,
        @Inject(WINDOW) private window: Window,
    ) {
        this.unsubscribe = new Subject();
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.initLoginForm();
        this.initEmailForm();
        this.queryParamsUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        // redirect back to the returnUrl before login
        // this.route.queryParams.subscribe(params => {
        //     this.returnUrl = params.returnUrl || '/';
        // });
        this.alternativeMethod = this.route.snapshot.paramMap.get('alt');

        console.log('Phone: ', this.loginForm.controls.phone);
        
    }




    /**
     * After Init
     */
    ngAfterViewInit() {
        this.ngxLoader.start();
        this.getDefaultCountry();
        setTimeout(() => {
            this.emailInput?.nativeElement.focus()
        });
        this.countryCode = this.webBookingService.getCompany()?.country?.code?.toLowerCase();

        grecaptcha.enterprise.ready(() => {
            this.widget_id = grecaptcha.enterprise.render("recaptcha-container", {
                "sitekey": "6LdJmYopAAAAABua8lUgtP7vqcnJdgvqS8IzyXPB"
            });
        });
    }

    async getDefaultCountry() {
        let ipapi = await this.auth.getIP().toPromise();
        let ipapiRes = await this.auth.getIpapiInfo(ipapi.ip).toPromise();
        let freegioRes;
        if (!ipapiRes?.country_code) {
            freegioRes = await this.auth.getFreegioipInfo().toPromise();
        }
        this.selectedCountry = ipapiRes?.country_code || freegioRes?.country_code || 'BH';
        this.getConf();
        this.cdr.markForCheck();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.authNoticeService.setNotice(null);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loading = false;
    }

    /**
     * Form initalization
     * Default params, validators
     */
    initLoginForm() {
        // demo message to show
        if (!this.authNoticeService.onNoticeChanged$.getValue()) {
            const initialNotice = this.translate.instant('PAGES.GENERAL.ENTER_VALID_PHONE_MSG');
            this.authNoticeService.setNotice(initialNotice, 'danger');
        }

        this.loginForm = this.fb.group({
            phone: new FormControl(undefined, [Validators.required]),
        });
    }

    /**
     * Form initalization
     * Default params, validators
     */
    initEmailForm() {
        this.emailForm = this.fb.group({
            email: new FormControl(undefined, [
                Validators.required,
                Validators.email,
                Validators.minLength(3),
                Validators.maxLength(320)
            ]),
        });
    }

    /**
     * Form Submit
     */
    submit() {

        /**
         * normal login.
         */
        if (!this.alternativeMethod) {
            let authData = {};
            let updateVerification = {};
            /**
             * login by mobile number
             */
            if (this.usedLoginMethod === 'mobile') {
                const controls = this.loginForm.controls;
                /** check form */
                if (this.loginForm.invalid) {
                    Object.keys(controls).forEach(controlName =>
                        controls[controlName].markAsTouched()
                    );
                    return;
                }
                this.loading = true;

                authData = {
                    mobile: controls.phone.value.e164Number,
                    country: this.countryCode
                };

                updateVerification = {
                    "method": "twilioSms",
                    "mobile": controls.phone.value.e164Number,
                };
            } else {
                const controls = this.emailForm.controls;
                /** check form */
                if (this.emailForm.invalid) {
                    Object.keys(controls).forEach(controlName =>
                        controls[controlName].markAsTouched()
                    );
                    return;
                }
                this.loading = true;
                authData = {
                    authEmail: controls.email.value.toLowerCase(),
                    country: this.countryCode
                };

                updateVerification = {
                    "method": "email",
                    "authEmail": controls.email.value.toLowerCase(),
                };
            }

            grecaptcha.enterprise.ready(() => {

                grecaptcha.enterprise.execute(this.widget_id, {action: 'login'}).then(token => {
                    this.recaptchaToken = token;

                    let isEmailAllowed = this.loginConfigurations.email;
                    let isMobileAllowed = this.loginConfigurations.mobile;

                    let submitWithMobile = this.usedLoginMethod === 'mobile';
                    let submitWithEmail = this.usedLoginMethod === 'email';


                    /**
                     * case 1: submit with email.
                     */
                    if (submitWithEmail) {
                        /**
                         * case 1.1: login by email is allowed.
                         */
                        if (isEmailAllowed) {
                            this.register(authData);
                        }
                        /**
                         * case 1.2: login by email is not allowed.
                         */
                        if (!isEmailAllowed) {
                            this.findCustomer({authEmail: authData['authEmail']}, authData, updateVerification);
                        }
                    }

                    /**
                     * case 2: submit with mobile.
                     */
                    if (submitWithMobile) {
                        /**
                         * case 2.1: login by mobile is allowed.
                         */
                        if (isMobileAllowed) {
                            this.register(authData, updateVerification);
                        }
                        /**
                         * case 2.2: login by mobile is not allowed.
                         */
                        if (!isMobileAllowed) {
                            this.findCustomer({mobile: authData['mobile'].substr(1)}, authData, updateVerification);
                        }
                    }

                });
            });
        } else {
            let updateVerification = {};

            if (this.alternativeMethod === 'mobile') {
                const controls = this.loginForm.controls;
                /** check form */
                if (this.loginForm.invalid) {
                    Object.keys(controls).forEach(controlName =>
                        controls[controlName].markAsTouched()
                    );
                    return;
                }

                updateVerification = {
                    "method": "twilioSms",
                    "mobile": controls.phone.value.e164Number.substr(1),
                };
            } else {
                const controls = this.emailForm.controls;
                /** check form */
                if (this.emailForm.invalid) {
                    Object.keys(controls).forEach(controlName =>
                        controls[controlName].markAsTouched()
                    );
                    return;
                }
                updateVerification = {
                    authEmail: controls.email.value.toLowerCase(),
                    method: 'email'
                };
            }

            grecaptcha.enterprise.ready(() => {
                grecaptcha.enterprise.execute(this.widget_id, {action: 'login'}).then(token => {
                    this.recaptchaToken = token;

                    let findParams = this.alternativeMethod === 'email' ? {authEmail: updateVerification['authEmail']} : {mobile: updateVerification['mobile']};
                    this.companyService.find(findParams).toPromise().then((res) => {
                        if (res.data?.exists && (this.alternativeMethod === 'email' ? res.data?.emailVerified : res.data?.verified)) {
                            this.presentPopup(this.translate.instant('PAGES.VERIFICATION.ALREADY_REGISTERED', {alternativeMethod: this.alternativeMethod === 'email' ? this.translate.instant('AUTH.INPUT.EMAIL') : this.translate.instant('AUTH.INPUT.PHONE')}));
                        } else {
                            this.auth.updateVerificationMethod(updateVerification).then((res) => {
                                localStorage.setItem('mob', res.data?.mobile || '');
                                localStorage.setItem('tok', res.data?.token);
                                localStorage.setItem('email', res.data?.authEmail || '');
                                this.router.navigate([`auth/verification/${updateVerification['mobile'] || updateVerification['authEmail']}`], {
                                    queryParams: {returnUrl: this.queryParamsUrl},
                                }); // Main page
                            }, (error) => {
                            }).finally(() => {
                                this.loading = false;
                                this.cdr.markForCheck();
                            })
                        }
                    }, (err) => {

                    }).finally(() => {
                        this.loading = false;
                        this.cdr.markForCheck();
                    });
                });
            });
        }
    }

    register(authData, updateVerification?) {
        let companyId = this.webBookingService.getCompany()?._id;
        authData.companyId = companyId;
        this.auth.register(authData, this.recaptchaToken).then((res) => {
            localStorage.setItem('mob', res.data?.mobile || '');
            localStorage.setItem('tok', res.data?.token);
            localStorage.setItem('email', res.data?.authEmail || '');
            if (this.usedLoginMethod === 'mobile') {
                if (res.data?.verificationMethod === 'firebase') {
                    this.auth.updateVerificationMethod(updateVerification).then((res) => {
                        localStorage.setItem('mob', res.data?.mobile || '');
                        localStorage.setItem('tok', res.data?.token);
                        localStorage.setItem('email', res.data?.authEmail || '');
                        this.router.navigate([`auth/verification/${authData['mobile']}`], {
                            queryParams: {returnUrl: this.queryParamsUrl}
                        }); // Main page
                    }, (error) => {
                    }).finally(() => {
                        this.loading = false;
                        this.cdr.markForCheck();
                    })
                } else {
                    this.router.navigate([`auth/verification/${authData['mobile']}`], {
                        queryParams: {returnUrl: this.queryParamsUrl}
                    }); // Main page
                    this.loading = false;
                    this.cdr.markForCheck();
                }
            } else {
                this.router.navigate([`auth/verification/${authData['authEmail']}`], {
                    queryParams: {returnUrl: this.queryParamsUrl}
                }); // Main page
            }
        }, (error) => {
            this.presentPopup(this.translate.instant('PAGES.GENERAL.GENERAL_ERROR'));
        }).finally(() => {
            this.loading = false;
            this.cdr.markForCheck();
        });
    }


    findCustomer(params, authData, updateVerification) {
        let type = params.mobile ? 'phone' : 'email';
        this.companyService.find(params).toPromise().then((res) => {
            let data = res.data;
            // check for user if not exist
            if (!data.exists || (type === 'email' && !data.emailVerified) || (type === 'phone' && !data.verified)) {
                this.presentPopup(this.translate.instant('PAGES.VERIFICATION.ERR_CUSTOMER_NOT_FOUND',{type:this.translate.instant('AUTH.INPUT.'+type.toUpperCase())}));
                return;
            }

            // check for user if have verified email or phone number
            if (type === 'phone' && data.hasEmail && data.emailVerified) {
                this.presentPopup(this.translate.instant('PAGES.VERIFICATION.PHONE_NOT_SUPPORTED',{email: data.authEmail}));
                return;
            }

            // check for user if have verified email or phone number
            if (type === 'email' && data.hasMobile && data.verified) {
                this.presentPopup(this.translate.instant('PAGES.VERIFICATION.EMAIL_NOT_SUPPORTED',{phone: data.mobile}));
                return;
            }

            // email or mobile is valid go to verification page
            // this.update(authData, updateVerification);
            this.auth.updateVerificationMethod(updateVerification).then((res) => {
                localStorage.setItem('mob', res.data?.mobile || '');
                localStorage.setItem('tok', res.data?.token);
                localStorage.setItem('email', res.data?.authEmail || '');
                this.router.navigate([`auth/verification/${params.mobile ? authData['mobile'] : authData['authEmail']}`], {
                    queryParams: {returnUrl: this.queryParamsUrl}
                }); // Main page
            }, (error) => {
            }).finally(() => {
                this.loading = false;
                this.cdr.markForCheck();
            })

        }, (error) => {
        }).finally(() => {
            this.loading = false;
            this.cdr.markForCheck();
        });
    }

    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.emailForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    switchToMobile() {
        this.initLoginForm();
        this.usedLoginMethod = 'mobile';
    }

    switchToEmail() {
        this.initEmailForm();
        this.usedLoginMethod = 'email';
        setTimeout(() => {
            this.emailInput?.nativeElement?.focus()
        });
    }

    getConf() {
        let companyId = this.webBookingService.getCompany()._id;
        this.companyService.getVerificationConfigurations(null, companyId).toPromise().then((res) => {
            this.loginConfigurations = res.data?.company;
            this.usedLoginMethod = this.loginConfigurations.primary;
            setTimeout(() => {
                this.emailInput?.nativeElement?.focus()
            });
            this.cdr.markForCheck();
        }).finally(() => {
            this.usedLoginMethod = this.loginConfigurations.primary;
            this.ngxLoader.stop();
            this.cdr.markForCheck();
        });
    }

    presentPopup(msg) {
        let instance = this.modalService.open(PrebookingPopupComponent, {
            centered: true,
        });
        instance.componentInstance.options = {
            text: msg,
            serviceName: this.translate.instant('PAGES.GENERAL.ATTENTION'),
            downloadApp: false,
            cancelButton: {active: true, text: this.translate.instant('PAGES.GENERAL.OK')}
        };
    }

    removeSpaces(str) {
        return str.replace(/\s/g, '')
    }

}
