import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {CompanyService} from "../../core/_base/crud/services/company/company.service";
import {WINDOW} from "../../core/_base/crud/providers/window.provider";
import {environment} from "../../../environments/environment";

declare const gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class WebBookingService {

    constructor(private companyService: CompanyService,
                public router: Router,
                @Inject(WINDOW) private window: Window) {
    }

    private company = null;


    public getCompany() {
        return this.company;
    }

    load() {
        let hostName = this.window.location.hostname;

        if(this.window.location.hostname !=='skiplino.live')
        {
            if (environment.production) {
            localStorage.removeItem('video');
            return new Promise((resolve, reject) => {
                this.companyService.getByURL({url: hostName})
                    .subscribe(response => {
                        this.company = response.data;
                        /*
                        if(window.location.href.indexOf("qrBooking") > -1)
                        {
                            gtag('event', 'DynamicTicketing', {
                                event_category: 'DynamicTicketing',
                                event_label: hostName,
                            });
                        }
                        else {
                            gtag('event', 'WebBooking', {
                                event_category: 'WebBooking',
                                event_label: hostName,
                            });
                        }
                        */
                        resolve(true);
                    });
            })
        } else {
            if (hostName === 'localhost') {
                let companyName = window.prompt("Enter Company URL: ");
                hostName = companyName || '902a2c4fc4';
                // hostName =  'de3919f9ec' || '902a2c4fc4';
            }
            if (hostName === 'video') {
                let callToken = window.prompt("Enter Call Token: ");
                this.router.navigateByUrl(`/call/video/${callToken}`);
                localStorage.setItem('video', 'yes');
            } else {
                localStorage.removeItem('video');
                return new Promise((resolve, reject) => {
                    this.companyService.getByURL({url: hostName})
                        .subscribe(response => {
                            this.company = response.data;
                            resolve(true);
                        }, (error) => {
                            this.company = null;
                            resolve(true);
                        });
                });
            }
        }
        }
    }

    //
    //  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    //   let hostName = this.window.location.hostname;
    //   // return this.companyService.getByURL({url: hostName}).subscribe((res) => {
    //   //   localStorage.setItem(hostName, JSON.stringify(res.data))
    //   //
    //   // .pipe(
    //   //       map((data) => {
    //   //         return {...data.data}
    //   //       }),
    //   //       catchError(error => {
    //   //         return of({error: error})
    //   //       })
    //   //   )
    //   //   return {...res.data};
    //   // }, (error) => {
    //   //   return of({error: error});
    //   // });
    //
    //   return this.companyService.getByURL({url: hostName})
    //       .pipe(
    //           map((data) => {
    //               console.log('in', data);
    //               localStorage.setItem(hostName, JSON.stringify(data.data))
    //             return {...data.data}
    //           }),
    //           catchError(error => {
    //             return of({error: error})
    //           })
    //       )
    // }
}
