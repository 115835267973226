<div *ngIf="false" ngbDropdown #drop="ngbDropdown" [placement]="selectedLanguage.code =='ar' ?'bottom-left' :'bottom-right'" class="dropdown dropdown-fluid">
<!-- the logo its show only on thank you page after rating -->
    <img class="feedbackLogo m-3" *ngIf="showSkiplinoLogo"  src="assets/media/skiplino/Skiplino-Logo.png">

    <!--begin::Toggle-->
    <div ngbDropdownToggle class="topbar-item">
        <div class="btn btn-icon btn-lg lang-btn mr-4 symbol symbol-20 w-auto">
            <img class="lang-img" [src]="'./assets/media/flags/' + (selectedLanguage?.flag | lowercase) + '.png'">
            <i class="lang-icon">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.92188 4.82031H10.1016" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M6.61714 3.80908L8.75197 4.82032C8.22763 5.94391 6.4149 9.02256 4.70703 10.1012" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M5.60742 6.28076C6.05686 6.99237 7.15798 8.5729 7.96697 9.20211" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M8.86517 13.1348H3.57038C2.1508 13.1348 1 11.984 1 10.5645V3.57038C1 2.1508 2.1508 1 3.57038 1H10.3397C11.7593 1 12.9101 2.1508 12.9101 3.57038V8.86517" stroke="var(--primary-color)" stroke-width="1.5"/>
                    <rect x="8.86523" y="8.86523" width="11.9101" height="12.1348" rx="2.57038" stroke="var(--primary-color)" stroke-width="1.5"/>
                    <path d="M12.3477 17.8539L14.6415 12.4321C14.709 12.2726 14.9344 12.2708 15.0044 12.4292L17.4038 17.8539" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M13.248 15.8315H16.5065" stroke="var(--primary-color)" stroke-width="1.5"/>
                    <path d="M3.02148 15.1572V16.9732C3.02148 17.956 3.81819 18.7527 4.80098 18.7527H6.61699" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M18.7539 6.61816L18.7539 4.80215C18.7539 3.81937 17.9572 3.02266 16.9744 3.02266L15.1584 3.02266" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                </svg>                    
            </i>
            <span class="lang-txt">
                {{ selectedLanguage?.code | uppercase }}
            </span>
            <i class="lang-drop-icon">
                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.64645 5.64645L0.853553 0.853553C0.538571 0.538571 0.761654 0 1.20711 0H10.7929C11.2383 0 11.4614 0.538571 11.1464 0.853554L6.35355 5.64645C6.15829 5.84171 5.84171 5.84171 5.64645 5.64645Z" fill="var(--primary-color)"/>
                </svg>                    
            </i>
        </div>
    </div>
    <!--end::Toggle-->

    <!--begin::Dropdown-->
    <div ngbDropdownMenu style="direction: ltr" class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm" [ngClass]="selectedLanguage.code =='ar' ? 'dropdown-menu-left' : 'dropdown-menu-right'" >
        <ul class="navi navi-hover py-4">
            <ng-container *ngFor="let language of languages">
                <li class="navi-item" [ngClass]="{'active': language === selectedLanguage}">
                    <a (click)="setSelectedLanguage(language); drop.close()" [ngClass]="{'active': language === selectedLanguage}"
                       class="navi-link">
						<span class="symbol symbol-20 mr-3">
							 <img [src]="'./assets/media/flags/' + (language.flag | lowercase) + '.png'">
						</span>
                        <span class=" navi-text">{{language.name}}</span>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
    <!--end::Dropdown-->
</div>

<div class="language-toggler" *ngIf="!isFlatList">
    <div [matMenuTriggerFor]="langMenu" class="topbar-item">
        <div class="btn btn-icon  btn-lg mr-1 symbol symbol-20 ">
            <div class="btn btn-icon btn-lg lang-btn mr-4 symbol symbol-20 w-auto">
                <img [src]="'./assets/media/flags/' + (selectedLanguage?.flag | lowercase) + '.png'">
                <i class="lang-icon">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.92188 4.82031H10.1016" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M6.61714 3.80908L8.75197 4.82032C8.22763 5.94391 6.4149 9.02256 4.70703 10.1012" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M5.60742 6.28076C6.05686 6.99237 7.15798 8.5729 7.96697 9.20211" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M8.86517 13.1348H3.57038C2.1508 13.1348 1 11.984 1 10.5645V3.57038C1 2.1508 2.1508 1 3.57038 1H10.3397C11.7593 1 12.9101 2.1508 12.9101 3.57038V8.86517" stroke="var(--primary-color)" stroke-width="1.5"/>
                        <rect x="8.86523" y="8.86523" width="11.9101" height="12.1348" rx="2.57038" stroke="var(--primary-color)" stroke-width="1.5"/>
                        <path d="M12.3477 17.8539L14.6415 12.4321C14.709 12.2726 14.9344 12.2708 15.0044 12.4292L17.4038 17.8539" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M13.248 15.8315H16.5065" stroke="var(--primary-color)" stroke-width="1.5"/>
                        <path d="M3.02148 15.1572V16.9732C3.02148 17.956 3.81819 18.7527 4.80098 18.7527H6.61699" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M18.7539 6.61816L18.7539 4.80215C18.7539 3.81937 17.9572 3.02266 16.9744 3.02266L15.1584 3.02266" stroke="var(--primary-color)" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>                    
                </i>
                <span class="lang-txt-full" style="display: none;">{{ selectedLanguage?.name }}</span>
                <span class="lang-txt">
                    {{ selectedLanguage?.code | uppercase }}
                </span>
                <i class="lang-drop-icon">
                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.64645 5.64645L0.853553 0.853553C0.538571 0.538571 0.761654 0 1.20711 0H10.7929C11.2383 0 11.4614 0.538571 11.1464 0.853554L6.35355 5.64645C6.15829 5.84171 5.84171 5.84171 5.64645 5.64645Z" fill="var(--primary-color)"/>
                    </svg>                    
                </i>                
            </div>
        </div>
    </div>

    <mat-menu #langMenu="matMenu">
        <ul class="navi navi-hover py-1">
            <ng-container *ngFor="let language of languages">
                <li class="navi-item lang-item" [ngClass]="{'active': language === selectedLanguage}">
                    <a (click)="setSelectedLanguage(language); drop.close()" [ngClass]="{'active': language === selectedLanguage}"
                       class="navi-link">
						<span class="symbol symbol-20 mr-3">
							 <img [src]="'./assets/media/flags/' + (language.flag | lowercase) + '.png'">
						</span>
                        <span class=" navi-text">{{language.name}} <span *ngIf="!isCompanyFound">({{ language['nameIn_'+selectedLanguage.code] }})</span> </span>
                    </a>
                </li>
            </ng-container>
        </ul>
    </mat-menu>
</div>

<div class="language-menu-list" *ngIf="isFlatList">
    <ul class="navi navi-hover py-1">
        <ng-container *ngFor="let language of languages">
            <li class="navi-item lang-item" [ngClass]="{'active': language.name === selectedLanguage.name}">
                <a (click)="setSelectedLanguage(language); drop.close()" [ngClass]="{'active': language === selectedLanguage}"
                   class="navi-link">
                    <span class="symbol symbol-20 mr-3">
                         <img [src]="'./assets/media/flags/' + (language.flag | lowercase) + '.png'">
                    </span>
                    <span class=" navi-text">{{language.name}} <span *ngIf="!isCompanyFound">({{ language['nameIn_'+selectedLanguage.code] }})</span></span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>