<!--begin::Header Menu Wrapper-->
<div #headerMenuOffcanvas class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
  <ng-container *ngIf="headerMenuSelfDisplay">
    <!--begin::Header Logo-->
    <div class="header-logo">
<!--      <a routerLink="/" >-->
<!--        <img alt="logo" [attr.src]="headerLogo" />-->
<!--      </a>-->
		<a routerLink="/">
			<img alt="logo" class="logo-img" [src]="logo" style="border-radius: 5px"/>
		</a>
    </div>
    <!--end::Header Logo-->
  </ng-container>

  <!--begin::Header Menu-->
<!--  <div ktMenu [options]="menuOptions" id="kt_header_menu" class="header-menu header-menu-mobile " [ngClass]="headerMenuClasses">-->
<!--    &lt;!&ndash;begin::Header Nav&ndash;&gt;-->
<!--    <ul class="menu-nav" [ngClass]="htmlClassService.getClasses('header_menu_nav', true)">-->
<!--			<ng-container *ngFor="let item of menuHorService.menuList$ | async">-->
<!--				<ng-container *ngIf="item.title" [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>-->
<!--			</ng-container>-->
<!--		</ul>-->
<!--    &lt;!&ndash;end::Header Nav&ndash;&gt;-->
<!--  </div>-->
  <!--end::Header Menu-->
</div>
<!--end::Header Menu Wrapper-->

<!--<ng-template #menuTemplate let-item="item" let-parentItem="parentItem">-->
<!--	<li [attr.aria-haspopup]="true"-->
<!--		[attr.data-menu-toggle]="getItemAttrSubmenuToggle(item)"-->
<!--		[ngClass]="getItemCssClasses(item)">-->

<!--		&lt;!&ndash; if item has submenu &ndash;&gt;-->
<!--		<ng-container *ngIf="item.submenu">-->
<!--			<a href="javascript:;" class="menu-link menu-toggle">-->

<!--				<ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>-->

<!--				<ng-container *ngIf="rootArrowEnabled">-->
<!--					&lt;!&ndash; arrow icons &ndash;&gt;-->
<!--					<i *ngIf="item.submenu && item.root" class="menu-arrow"></i>-->
<!--				</ng-container>-->
<!--				&lt;!&ndash; else arrow icons &ndash;&gt;-->
<!--				<i *ngIf="item.submenu && !item.root" class="menu-arrow"></i>-->
<!--			</a>-->
<!--		</ng-container>-->

<!--		&lt;!&ndash; if item hasn't sumbenu &ndash;&gt;-->
<!--		<ng-container *ngIf="!item.submenu">-->
<!--			<a [routerLink]="item.page" class="menu-link">-->
<!--				<ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>-->
<!--			</a>-->
<!--		</ng-container>-->

<!--		&lt;!&ndash; if menu item has submenu child then recursively call new menu item component &ndash;&gt;-->
<!--		<ng-container *ngIf="item.submenu">-->
<!--			<div class="menu-submenu" [ngClass]="getItemMenuSubmenuClass(item)" [ngStyle]="{ 'width': item.submenu.width }">-->
<!--				<span class="menu-arrow" [ngClass]="{ 'menu-arrow-adjust': item.root }"></span>-->

<!--				<ul *ngIf="item.submenu?.length" class="menu-subnav">-->
<!--					<ng-container *ngFor="let child of item.submenu">-->
<!--						<ng-container [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ item: child, parentItem: item }">-->
<!--						</ng-container>-->
<!--					</ng-container>-->
<!--				</ul>-->

<!--				<ul *ngIf="item.submenu.items?.length" class="menu-subnav">-->
<!--					<ng-container *ngFor="let child of item.submenu.items">-->
<!--						<ng-container [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ item: child, parentItem: item }">-->
<!--						</ng-container>-->
<!--					</ng-container>-->
<!--				</ul>-->

<!--				<div *ngIf="item.submenu.type === 'mega' && item.submenu.columns?.length" class="menu-subnav">-->
<!--					<ul class="menu-content">-->
<!--						<ng-container *ngFor="let child of item.submenu.columns">-->
<!--							<ng-container [ngTemplateOutlet]="menuColumnTemplate" [ngTemplateOutletContext]="{ item: child }"></ng-container>-->
<!--						</ng-container>-->
<!--					</ul>-->
<!--				</div>-->

<!--			</div>-->
<!--		</ng-container>-->
<!--	</li>-->
<!--</ng-template>-->

<!-- item inner -->
<!--<ng-template #menuItemInnerTemplate let-item="item" let-parentItem="parentItem">-->
<!--	&lt;!&ndash; if menu item has icon &ndash;&gt;-->
<!--	<ng-container *ngIf="item.icon">-->
<!--		<i class="menu-icon" [ngClass]="item.icon"></i>-->
<!--	</ng-container>-->

<!--	<ng-container *ngIf="!item.icon">-->
<!--		&lt;!&ndash; if menu item using bullet &ndash;&gt;-->
<!--		<i *ngIf="parentItem && parentItem.bullet === 'dot' || item.bullet === 'dot'" class="menu-link-bullet menu-link-bullet&#45;&#45;dot">-->
<!--			<span></span>-->
<!--		</i>-->
<!--		<i *ngIf="parentItem && parentItem.bullet === 'line' || item.bullet === 'line'" class="menu-link-bullet menu-link-bullet&#45;&#45;line">-->
<!--			<span></span>-->
<!--		</i>-->
<!--	</ng-container>-->

<!--	<ng-container *ngIf="!item.badge; else menuLinkBadge">-->
<!--		<span class="menu-item-here"></span>-->
<!--		&lt;!&ndash; menu item title text &ndash;&gt;-->
<!--		<span class="menu-text" [translate]="item.translate">-->
<!--			{{item.title}}-->
<!--		</span>-->
<!--	</ng-container>-->

<!--	<ng-template #menuLinkBadge>-->
<!--		&lt;!&ndash; menu item with badge &ndash;&gt;-->
<!--		<span class="menu-text" [translate]="item.translate">{{item.title}}</span>-->
<!--		<span class="menu-link-badge">-->
<!--			<span class="kt-badge kt-badge&#45;&#45;brand kt-badge&#45;&#45;inline kt-badge&#45;&#45;pill" [ngClass]="item.badge.type" [translate]="item.badge.translate">{{item.badge.value}}</span>-->
<!--		</span>-->
<!--	</ng-template>-->
<!--</ng-template>-->

<!-- item column -->
<!--<ng-template #menuColumnTemplate let-item="item">-->
<!--	<li class="menu-item">-->
<!--		<h3 class="menu-heading menu-toggle">-->
<!--			<span class="menu-text" [translate]="item.heading.translate">-->
<!--				{{item.heading.title}}-->
<!--			</span>-->
<!--			<i class="menu-ver-arrow la la-angle-right"></i>-->
<!--		</h3>-->
<!--		<ng-container *ngIf="item.items?.length">-->
<!--			<ul class="menu-inner">-->
<!--				<ng-container *ngFor="let child of item.items">-->
<!--					<ng-container [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ item: child, parentItem: item }">-->
<!--					</ng-container>-->
<!--				</ng-container>-->
<!--			</ul>-->
<!--		</ng-container>-->
<!--	</li>-->
<!--</ng-template>-->
