// ARABIC
export const locale = {
    lang: 'ar',
    data: {
        TRANSLATOR: {
            SELECT: 'اختر اللغة',
        },
        MENU: {
            NEW: 'new',
            ACTIONS: 'Actions',
            CREATE_POST: 'Create New Post',
            PAGES: 'Pages',
            FEATURES: 'Features',
            APPS: 'Apps',
            DASHBOARD: 'Dashboard',
        },
        AUTH: {
            GENERAL: {
                OR: 'ايضا يمكنك',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: 'Don\'t have an account?',
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'Forgot Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
            },
            LOGIN: {
                TITLE: 'Login Account',
                BUTTON: 'Sign In',
            },
            FORGOT: {
                TITLE: 'Forgotten Password?',
                DESC: 'Enter your email to reset your password',
                SUCCESS: 'Your account has been successfully reset.'
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered.'
            },
            INPUT: {
                EMAIL: 'البريد الالكتروني',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                USERNAME: 'Username',
                PHONE: 'الهاتف',
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'حقل مطلوب',
                MIN_LENGTH_FIELD: 'اقل طول مطلوب:',
                MAX_LENGTH_FIELD: 'اكثر طول مطلوب:',
                MIN_MULTI_SELECT: 'حد أدنى',
                MAX_MULTI_SELECT: 'حد أقصى',
                OPTIONS: 'خيارات',
                INVALID_FIELD: 'الحقل غير صحيح',
            }
        },
        ECOMMERCE: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'Selected records count: ',
                ALL: 'All',
                SUSPENDED: 'Suspended',
                ACTIVE: 'Active',
                FILTER: 'Filter',
                BY_STATUS: 'by Status',
                BY_TYPE: 'by Type',
                BUSINESS: 'Business',
                INDIVIDUAL: 'Individual',
                SEARCH: 'Search',
                IN_ALL_FIELDS: 'in all fields'
            },
            ECOMMERCE: 'eCommerce',
            CUSTOMERS: {
                CUSTOMERS: 'Customers',
                CUSTOMERS_LIST: 'Customers list',
                NEW_CUSTOMER: 'New Customer',
                DELETE_CUSTOMER_SIMPLE: {
                    TITLE: 'Customer Delete',
                    DESCRIPTION: 'Are you sure to permanently delete this customer?',
                    WAIT_DESCRIPTION: 'Customer is deleting...',
                    MESSAGE: 'Customer has been deleted'
                },
                DELETE_CUSTOMER_MULTY: {
                    TITLE: 'Customers Delete',
                    DESCRIPTION: 'Are you sure to permanently delete selected customers?',
                    WAIT_DESCRIPTION: 'Customers are deleting...',
                    MESSAGE: 'Selected customers have been deleted'
                },
                UPDATE_STATUS: {
                    TITLE: 'Status has been updated for selected customers',
                    MESSAGE: 'Selected customers status have successfully been updated'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Customer has been updated',
                    ADD_MESSAGE: 'Customer has been created'
                }
            }
        },
        PAGES: {
            GENERAL: {
                BRANCHES: 'الفروع',
                LOGOUT: 'خروج',
                LOGIN: 'دخول',
                SEARCH_PLACEHOLDER: 'ابحث...',
                BOOK: 'احجز',
                KM: 'كم',
                ENABLE_LOCATION_MSG: 'الرجاء تمكين خدمة تحديد المواقع من المتصفح ليتسنى لك رؤية مسافات الفروع',
                ENABLE_LOCATION_MSG2: 'الرجاء تمكين خدمة تحديد المواقع من المتصفح لتحصل على افضل مستوى خدمة ممكنة',
                BEFORE_GETTING_STARTED: 'قبل آن نبدأ...',
                ENTER_EMAIL_RESET_PASSWORD: 'الرجاء ادخال البريد الالكتروني لإعادة تعيين الرقم السري',
                ENTER_EMAIL: 'الرجاء ادخال البريد الالكتروني',
                PHONE_NUMBER_VERIFY_MSG: 'الرجاء تأكيد رقم الهاتف لإكمال عملية الحجز',
                ENTER_YOUR: "الرجاء إدخال",
                PHONE_NUMBER: 'رقم الهاتف',
                EMAIL: 'البريد الإلكتروني',
                ENTER_PHONE_NUMBER: 'الرجاء إدخال رقم الهاتف',
                EMAIL_VERIFY_MSG: 'الرجاء تأكيد بريدك الإلكتروني لإكمال عملية الحجز',
                SECURE_BOOKING_MSG: 'ستضمن هذه الخطوة أن عملية الحجز آمنة',
                LOGIN_BY_EMAIL: 'تسجيل الدخول عن طريق البريد الإلكتروني متاح فقط للمستخدمين الذين لديهم بالفعل حسابات مسجلة عبر البريد الإلكتروني',
                LOGIN_BY_PHONE: 'تسجيل الدخول عن طريق رقم الهاتف متاح فقط للمستخدمين الذين لديهم بالفعل حسابات مسجلة بأرقام هواتفهم',
                USE_MOBILE: 'استخدم رقم الهاتف المحمول بدلا من ذلك',
                USE_EMAIL: 'استخدم بريدك الإلكتروني بدلاً من ذلك',
                ALREADY_HAVE_PHONE: 'لديك بالفعل حساب مسجل برقم هاتفك؟',
                ALREADY_HAVE_EMAIL: 'لديك بالفعل حساب مسجل باستخدام بريدك الإلكتروني؟',
                NEXT_STEP: 'التالي',
                LATER: 'لاحقاً',
                BACK: 'رجوع',
                SIGNUP_AGREEMENT: 'بالتسجيل فإنك توافق على',
                TERMS_AND_CONDITIONS: 'البنود و الشروط',
                TERMS: 'الشروط',
                AND_SIGN: 'و',
                PRIVACY_POLICY: 'سياسة الخصوصية',
                RIGHTS_RESERVED: 'كل الحقوق محفوظة',
                ENTER_VALID_PHONE_MSG: 'الرجاء ادخال رقم هاتف صحيح',
                ENTER_VALID_EMAIL_MSG: 'الرجاء ادخال بريد الكتروني صحيح',
                CHANGE: 'تغيير',
                OPEN: 'مفتوح',
                CLOSED: 'مغلق',
                WORKING_HOURS: 'أوقات العمل',
                SHOW_MORE: 'المزيد',
                SHOW_LESS: 'إخفاء',
                DAY_OFF: 'يوم إجازة',
                TODAY: 'اليوم',
                FROM: 'من',
                TO: 'إلى',
                POPULAR_TIMES: 'أوقات الذروة',
                SUNDAY: 'الأحد',
                MONDAY: 'الاثنين',
                TUESDAY: 'الثلثاء',
                WEDNESDAY: 'الأربعاء',
                THURSDAY: 'الخميس',
                FRIDAY: 'الجمعة',
                SATURDAY: 'السبت',
                NO_DATA: 'لا توجد معلومات متوفرة!',
                SERVICES: 'الخدمات',
                SELECTEDSERVICES: 'الخدمات المختارة',
                SERVICE: 'خدمة',
                QUEUES: 'الطوابير',
                SELECTEDQUEUES: 'المختارة الطوابير',
                TRANSPORT: 'وسيلة النقل',
                WALK: 'المشي',
                CAR: 'القيادة',
                TRANSIT: 'النقل العام',
                SELECT: 'اختر',
                TIME: 'الوقت',
                DATE: 'التاريخ',
                VERIFY: 'تحقق',
                ATTENTION: 'تنبيه!',
                CLOSE: 'إغلاق',
                TICKET: 'تذكرة',
                AGENT: 'العميل',
                ENABLE: 'تفعيل',
                TEXT_MESSAGE: 'رسالة نصية',
                SEND_SMS: 'إرسل رسالة نصية',
                SEND_EMAIL: 'إرسل بريد إلكتروني',
                USE_EMAIL_ADDRESS: 'إستخدم البريد الإلكتروني',
                USE_MOBILE_NUMBER: 'إستخدم رقم الموبايل',
                MOBILE_NUMBER: 'رقم الموبايل',
                VERIFY_WITH_YOUR: 'التحقق بإستخدام',
                OTP: 'كلمة مرور لمرة واحدة  (OTP)',
                PRINT: 'اطبع',
                OK: 'حسناً',
                OOPS: 'حصل خطأ ما!',
                CONTINUE: 'متابعة',
                PURCHASE: 'شراء',
                STAY_IN_QUEUE: 'البقاء في قائمة الانتظار',
                VIRTUAL_CALL_MSG: 'أنت تحجز خدمة مكالمات الفيديو. يرجى تنزيل تطبيق Skiplino للجوال بعد حجز تذكرتك للسماح باستقبال مكالمة الفيديو.',
                SKIPLINO: 'سكيبلاينو',
                NETWORK_ERR: 'خطأ في الشبكة',
                NETWORK_ERR_MSG: 'الشبكة معطلة أو لا يمكن الوصول إلى خدمة الموقع',
                NETWORK_ERR_MSG2: 'انتهت مهلة المحاولة قبل أن تتمكن من الحصول على بيانات الموقع',
                GENERAL_ERROR: 'لقد حدث خطأ، الرجاء إعادة المحاولة لاحقاً',
                ERROR_JOIN_CALL: 'حدث خطأ أثناء الانضمام إلى المكالمة!',
                COUNTDOWN_D: ' يوم',
                COUNTDOWN_H: ' ساعة',
                COUNTDOWN_M: ' دقيقة',
                COUNTDOWN_S: ' ثانية',
                TOO_MANY_ATTEMPTS_PLEASE_TRY_AGAIN_LATER: 'لقد قمت بالكثير من المحاولات، يرجى المحاولة مرة أخرى لاحقًا',
                BOOK_VIA_VIRTUAL_CALL: 'احجز عن طريق مكالمة فيديو'
            },
            VERIFICATION: {
                ENTER_CODE: 'الرجاء ادخال رمز التأكيد',
                CODE_SENT_TO: 'رمز التأكيد تم ارساله عبر',
                CHECK_JUNK: 'يرجى التحقق من مجلد البريد العشوائي / غير المرغوب فيه في حالة عدم العثور عليه في صندوق الوارد الخاص بك',
                VERIFICATION_CODE: 'رمز التأكيد',
                HAVE_NOT_RECEIVE_CODE: 'لم تستلم أي رمز تأكيد؟',
                RESEND: 'إعادة إرسال',
                RESEND_CODE: 'إعادة إرسال رمز التأكيد',
                CALL: 'اتصال',
                CALL_MSG: 'ستتلقى مكالمة برمز تحقق جديد!',
                SENT_SUCCESS: 'تم إرسال رمز تحقق جديد بنجاح!',
                CANNOT_VERIFY_CODE: 'لا يمكن التحقق من الرمز. يرجى استخدام إحدى الطرق البديلة أو التأكد من استخدام رمز التحقق الذي تم ارساله',
                CALL_ME: 'اتصل بي',
                SEND_WHATSAPP: 'أرسل عبر واتساب',
                ALREADY_REGISTERED: 'تم تسجيل {{alternativeMethod}} بالفعل مع حساب آخر.',
                ERR_CUSTOMER_NOT_FOUND: 'لا يوجد مستخدم مسجل بهذا {{type}}',
                PHONE_NOT_SUPPORTED: 'لم يعد تسجيل الدخول باستخدام الهاتف مدعومًا لهذا الحساب. يرجى تسجيل الدخول باستخدام عنوان البريد الإلكتروني المرتبط بهذا الحساب ({{email}})',
                EMAIL_NOT_SUPPORTED: 'تسجيل الدخول بالبريد الإلكتروني لم يعد مدعوماً لهذا الحساب. الرجاء تسجيل الدخول باستخدام رقم الهاتف المرتبط بهذا الحساب ({{phone}})',
                VERIFY_WITH_YOUR: 'التحقق بإستخدام',
                OTP: 'كلمة مرور لمرة واحدة  (OTP)',
            },
            BOOKING: {
                ENABLE_LOCATION_MSG3: 'الرجاء تمكين خدمة الموقع لمعرفة التوقيت التقديري الصحيح',
                ENABLE_LOCATION_MSG4: 'الرجاء تمكين خدمة الموقع للتمكن من حجز التذكرة',
                ENABLE_NOTIFICATION_MSG: 'الرجاء تمكين خدمة الإشعارات للحصول على تحديثات التذكرة',
                SLOTS_FULLY_BOOKED: 'المواعيد محجوزة بالكامل أو غير متوفرة!',
                TIME_BASED_ON_TIMEZONE: 'تعتمد الأوقات المعروضة على المنطقة الزمنية {{TimeZone}}',
                FILL_FIELDS: 'املأ الحقول',
                ENABLE_LOCATION_BOOK_SERVICE: 'يرجى التأكد من تمكين خدمة الموقع لحجز هذه الخدمة!',
                SERVICE_CLOSED_HOURS: 'تم اغلاق الخدمة مؤقتاً ، ساعات العمل:',
                SERVICE_CLOSED_DAYS: 'تم اغلاق الخدمة مؤقتاً ، أيام العمل:'
            },
            CALL: {
                INVALID_URL: 'رابط غير صحيح',
                ENABLE_MIC: 'يرجى تمكين المتصفح للوصول إلى الميكروفون ثم حاول الانضمام مرة أخرى',
                ENABLE_CAMERA: 'للحصول على التجربة الكاملة ، تحتاج إلى السماح للمتصفح بالوصول إلى الكاميرا.',
                JOIN: 'انضمام',
                LEAVE: 'خروج',
                FLIP: 'تدوير',
                MUTE_AUDIO: 'كتم الصوت',
                MUTE_VIDEO: 'اغلاق الصورة',
                STAND_MSG: 'يمكنك حجز مكانك في الطابور قبل وصولك'
            },
            TICKET: {
                ALL_SET: 'تم الحجز بنجاح!',
                DOWNLOAD_APP: 'قم بتنزيل التطبيق للوصول إلى التذكرة أو تحديثها أو إلغائها',
                TIME_LEFT: 'الوقت المتبقي',
                STATUS: 'الحالة',
                BOOKED_FOR: 'محجوزة لـ',
                TICKET_SENT_TO: 'تم ارسال التذكرة إلى',
                SELECT_BELOW: 'أو قم بالاختيار مما يلي',
                CHECK_OUT: 'تسجيل خروج',
                WAITING: 'قيد الانتظار',
                CALLED: 'حان دورك',
                RECALLED: 'النداء الأخير',
                DONE: 'منجز',
                'NO-SHOW': 'لم تحضر',
                CANCELLED: 'ألغيت',
                CANNOT_CREATE_TICKET_NOT_AVAILABLE: 'عذرًا، توقيت الموعد الذي اخترته لم يعد متاحًا.',
                CANNOT_CREATE_TICKET_HAVE_WAITING_TICKET: 'لا يمكن إنشاء تذكرة. لديك بالفعل تذكرة لهذه الخدمة.',
                CANNOT_CREATE_TICKET_MAXIMUM_CANCEL: 'لقد وصلت إلى الحد الأقصى لعدد مرات إلغاء التذاكر.',
                CANNOT_CREATE_TICKET_CANNOT_CREATE_MAXIMUM_CANCEL: 'لا يمكن إنشاء تذكرة. لقد وصلت إلى الحد الأقصى لعدد مرات إلغاء التذاكر.',
                CANNOT_CREATE_TICKET_SELECTED_NOT_AVAILABLE: 'التاريخ/الوقت المحدد غير متاح.',
                CANNOT_CREATE_TICKET_SERVICE_CLOSED: 'نأسف، هذه الخدمة مغلقة الآن',
                PEOPLE_WAITING: "المنتظرون",
                ESTIMATED_WAITING: "وقت الانتظار التقديري",
                MINUTE: 'دقيقة',
                MINUTES: 'دقيقة',
                TICKET_SAFE: 'حافظ على تذكرتك آمنة!',
                YOU_WILL_BE_SERVED_SOON: 'سيتم خدمتكم بعد قليل',
                CONGRATULATIONS: 'مبروك',
                TICKET_UPGRADED: 'تم ترقية تذكرتك إلى المسار السريع',
                FAST_TRACK: 'خدمة المسار السريع',
                UPGRADE: 'ترقية',
                GET: "إحصل",
                SERVED_QUICKER: 'على خدمة أسرع',
                FAST_TRACK_TICKET: 'تذكرة المسار السريع!',
                UPGRADE_YOUR_TICKET_FOR: 'ارتقِ بتذكرتك بِ',
                WITH: 'مع',
                WITHOUT: 'بدون',
                THE_WAITING_TIME_IS_CURRENTLY: 'الوقت  الحالي المتوقع للانتظار',
                TO_BE_SERVED: 'في الطابور',
                BE_THE: 'كن',
                FIRST: 'الأول',
                GET_SERVED_IN: 'سيتم خدمتك خلال',
                SKIPLINO_FAST_TRACK: 'خدمة المسار السريع من سكيبلاينو',
                AT_LEAST_850_FROM_BRANCH: 'للحجز يجب أن تكون في حدود ٨٥٠ متر من موقع الفرع.',
                VERIFICATION_FAILED_PLEASE_RE_SCAN_QR_CODE: 'فشل في التحقق، الرجاء محاولة مسح رمز الاستجابة السريع مرة أخرى',
                INVALID_BOOK_QR_REQUEST: 'رمز الاستجابة السريع غير صالح',
                PLEASE_ENABLE_YOUR_DEVICE_LOCATION: 'الرجاء تفعيل موقع الجهاز',
                QR_IS_NOT_ENABLED_FOR_THIS_COMPANY: 'رمز الاستجابة السريع غير مفعل لهذه الشركة',
                QR_TICKET_NOT_CREATED: 'فشل اصدار تذكرة',
                RATE_YOUR_EXPERIENCE: 'قيم تجربتك',
                ADDITIONAL_COMMENTS: 'تعليقات إضافية',
                OPTIONAL: 'اختياري',
                THANKYOU: 'شكرا لك!',
                FEEDBACK_SENT: 'تم إرسال تقييمك',
                GET_SERVED_FASTER: 'أحصل على خدمة أسرع',
                NEXT_VISIT: 'عند زيارتك في المرة القادمة عبر',
                SKIPLINO_APP: 'تطبيق سكيبلاينو',
                DOWNLOAD_SKIPLINO: 'حمّل سكيبلاينو',
                SERVED_AT: 'وقت تقديم الخدمة',
                DURATION: 'المدة',
                SUBMIT_FEEDBACK: 'إرسال التقييم',
                DESK: 'Desk'

            },
            LANDING: {
                HEADER_ACTION_ABOUT: 'عن سكيبلاينو',
                // إطلاق خاصية التذاكر المرنة 
                ABOVE_UPPER_TEXT: 'إطلاق خاصية',
                ABOVE_MAIN_TITLE_TOP: 'التذاكر',
                ABOVE_MAIN_TITLE_BOTTOM: 'المرنة',
                ABOVE_SUBTITLE: 'تحسين  <span class="line_full"></span> تجربة الحجز في الموقع ',

                PROCESS_TITLE: 'آلية العمل',
                PROCESS_1ST_STEP: 'الخطوة الاولى',
                PROCESS_1ST_TITLE: 'التذكرة المرنة',
                PROCESS_1ST_DESCRIPTION: 'مسح رمز الاستجابة السريع لحجز الخدمة المطلوبة عبر الجوال.',

                PROCESS_2ND_STEP: 'الخطوة الثانية',
                PROCESS_2ND_TITLE: 'حجز الخدمة',
                PROCESS_2ND_DESCRIPTION: 'اختيار الخدمة والإستمتاع <span class="line_full"></span> بمتابعة تحديثات التذكرة مباشرة أثناء الانتظار.',

                PROCESS_3RD_STEP: 'الخطوة الثالثة',
                PROCESS_3RD_TITLE: 'مناداة التذكرة',
                PROCESS_3RD_DESCRIPTION: 'التوجه إلى الخدمة فور استلام <span class="line_full"></span> الإشعار على الجوال عند مناداة التذكرة.',

                PROCESS_4TH_STEP: 'الخطوة الرابعة',
                PROCESS_4TH_TITLE: 'تقييم التجربة ',
                PROCESS_4TH_DESCRIPTION: 'تقييم التجربة حول الخدمة عبر الجوال.',

                BENEFITS_TITLE: 'فوائد التذاكر المرنة',

                GET_STARTED_TITLE: 'مستعد للبدء؟',
                GET_STARTED_SUBTITLE: 'قم بانشاء حساب الآن للبدء بإستخدام النظام مباشرة أو تواصل معنا لمساعدتك في تصميم حزمة مخصصة لشركتك.',
                GET_STARTED_ACTION_CREATE: 'انشاء حساب',
                GET_STARTED_ACTION_CONTACT: 'تواصل مع المبيعات',

            }
        }
    }
};