// Angular
import { Component } from '@angular/core';
import {LayoutConfigService} from '../../../../core/_base/layout';
import {currentUser, Logout, User} from "../../../../core/auth";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../core/reducers";
import {Observable} from "rxjs";
import {WebBookingService} from "../../../../resolvers/webBooking/web-booking.service";

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  searchDisplay = true;
  notificationsDisplay = true;
  quickActionsDisplay = true;
  cartDisplay = true;
  quickPanelDisplay = true;
  languagesDisplay = true;
  userDisplay = true;
  userLayout = 'offcanvas';
  userDropdownStyle = 'light';
  user$: Observable<User>;
  company;

  constructor(private layoutConfigService: LayoutConfigService, private store: Store<AppState>, private webBookingService: WebBookingService) {
    this.searchDisplay = this.layoutConfigService.getConfig('extras.search.display');
    this.notificationsDisplay = this.layoutConfigService.getConfig('extras.notifications.display');
    this.quickActionsDisplay = this.layoutConfigService.getConfig('extras.quick-actions.display');
    this.cartDisplay = this.layoutConfigService.getConfig('extras.cart.display');
    this.quickPanelDisplay = this.layoutConfigService.getConfig('extras.quick-panel.display');
    this.languagesDisplay = this.layoutConfigService.getConfig('extras.languages.display');
    this.userDisplay = this.layoutConfigService.getConfig('extras.user.display');
    this.userLayout = this.layoutConfigService.getConfig('extras.user.layout');
    this.userDropdownStyle = this.layoutConfigService.getConfig('extras.user.dropdown.style');
    this.user$ = this.store.pipe(select(currentUser));
  }

  /**
   * On init
   */
  ngOnInit() {
    this.company = this.webBookingService.getCompany();
  }

  /**
   * Log out
   */
  logout() {
    this.store.dispatch(new Logout());
  }
}
