<section class="lnd_above">
  <div class="lnd_inner_container">
    <div class="inner_text">
      <span class="above_txt lnd_animate">{{"PAGES.LANDING.ABOVE_UPPER_TEXT" | translate}}</span>
      <h1 class="main_title">
        <span class="line_full lnd_animate">{{"PAGES.LANDING.ABOVE_MAIN_TITLE_TOP" | translate}}</span><span class="line_full lnd_animate">{{"PAGES.LANDING.ABOVE_MAIN_TITLE_BOTTOM" | translate}}</span>
      </h1>
      <p class="sub_title lnd_animate" [innerHtml]='"PAGES.LANDING.ABOVE_SUBTITLE" | translate'></p>
    </div>
    <div class="bg_image">
      <img src="/assets/media/landing/above-bg.png" alt="" />
    </div>
  </div>
</section>
