import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { TranslationService } from "src/app/core/_base/layout";

@Component({
  selector: "kt-landing",
  animations: [
    trigger("smoothCollapse", [
      state(
        "initial",
        style({
          height: "0",
          overflow: "hidden",
          visibility: "hidden",
        })
      ),
      state(
        "final",
        style({
          overflow: "hidden",
        })
      ),
      transition("initial<=>final", animate("300ms")),
      //   transition(':enter', [
      //     style({ opacity: 0 }),
      //     animate('100ms', style({ opacity: 1 })),
      //   ]),
      //   transition(':leave', [
      //     animate('100ms', style({ opacity: 0 }))
      //   ])
    ]),
    trigger("smoothImgTransition", [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
  ],
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  entries: NodeListOf<Element> | undefined;
  today: number = Date.now();
  isCollapsed = true;
  selectedBenifit = null;
  currentLang = null;
  benefits = [
    {
      title: "Stay alerted",
      content:
        "Receive real-time notifications of your position in the queue, allowing users the freedom to wait anywhere. This eliminates the necessity of on-site waiting and allows users to return at their convenience without compromising service excellence.",
      imgUrl: "/assets/media/landing/benefit1.png",
      active: true,
      en: {
        title: "Stay alerted",
        content:
          "Receive real-time notifications of your position in the queue, allowing users the freedom to wait anywhere. This eliminates the necessity of on-site waiting and allows users to return at their convenience without compromising service excellence.",
      },
      ar: {
        title: "ابقَ على اطلاع ",
        content:
          "إشعارات فورية عن موقعك في الطابور، مما يتيح للعملاء حرية الانتظار في أي مكان و العودة في الوقت المناسب الى الموقع دون المساس بجودة الخدمة.",
      },
    },
    {
      title: "Efficiency",
      content:
        "Maximize your time while waiting by completing forms or providing additional information effortlessly on your personal devices. Our system enables you to make efficient use of your waiting time, ensuring a seamless and productive experience.",
      imgUrl: "/assets/media/landing/benefit2.png",
      en: {
        title: "Efficiency",
        content:
          "Maximize your time while waiting by completing forms or providing additional information effortlessly on your personal devices. Our system enables you to make efficient use of your waiting time, ensuring a seamless and productive experience.",
      },
      ar: {
        title: "الفعالية",
        content:
          "استغلال وقت الانتظار بأقصى فائدة من خلال ملء النماذج أو تقديم معلومات إضافية بسهولة عبرالجوال. نظامنا يتيح الاستفادة من وقت الانتظار بكفاءة، مما يضمن للعملاء تجربة سلسة.",
      },
    },
    {
      title: "Collecting User Feedback",
      content:
        "Skiplino enables feedback sharing to better understand needs and respond promptly to enhance services, without the discomfort of rating the service in the presence of an agent.",
      imgUrl: "/assets/media/landing/benefit3.png",

      en: {
        title: "Collecting User Feedback",
        content:
          "Skiplino enables feedback sharing to better understand needs and respond promptly to enhance services, without the discomfort of rating the service in the presence of an agent.",
      },
      ar: {
        title: "جمع ملاحظات العملاء",
        content:
          "يتيح سكيبلاينو امكانية مشاركة المستخدمين لملاحظاتهم و آرائهم عبر التطبيق مباشرة، لمساعدة الشركات تقييم الخدمات المقدمة والاستجابة بسرعة لتحسينها.",
      },
    },
    {
      title: "Reduce Carbon Footprint",
      content:
        "The transition to a paperless approach has significantly contributed to reducing paper waste and minimising the carbon footprint. With the fast track plan, there is no requirement for physical tickets; instead, users will receive their dynamic tickets on their mobile devices.",
      imgUrl: "/assets/media/landing/benefit4.png",
      en: {
        title: "Reduce Carbon Footprint",
        content:
          "The transition to a paperless approach has significantly contributed to reducing paper waste and minimising the carbon footprint. With the fast track plan, there is no requirement for physical tickets; instead, users will receive their dynamic tickets on their mobile devices.",
      },
      ar: {
        title: "المساهمة في خفض الانبعاثات الكربونية",
        content:
          "التحول لنظام لا ورقي يساهم بصورة كبيرة في تقليل المخلفات الورقية وخفض الانبعاثات الكربونية التي تؤثر بشكل سلبي على البيئة. من خلال التذاكر المرنة ، لا حاجة للتذاكر الورقية؛ بدلاً من ذلك، سيتلقى العملاء تذاكرهم المرنة على أجهزتهم المحمولة.",
      },
    },
    {
      title: "Less Hardware",
      content:
        "Streamline your operations and eliminate concerns about hardware costs and maintenance. With the QR code scanning plan, customers can book through their mobile devices and stay updated on their queue status.",
      imgUrl: "/assets/media/landing/benefit5.png",
      en: {
        title: "Less Hardware",
        content:
          "Streamline your operations and eliminate concerns about hardware costs and maintenance. With the QR code scanning plan, customers can book through their mobile devices and stay updated on their queue status.",
      },
      ar: {
        title: "أجهزة أقل",
        content:
          "تبسيط العمليات اليومية والتخلص من القلق بشأن تكاليف الأجهزة وصيانتها. مع خطة مسح رمز الاستجابة السريع و التذاكر المرنة،، يمكن للعملاء الحجز من خلال الجوال والبقاء على اطلاع على حالة الطابور.",
      },
    },
    {
      title: "Adaptation to Modern Trends",
      content:
        "In an era where digital and fast-paced services are valued, implementing this system positions the company as adaptable to modern trends and customer preferences.",
      imgUrl: "/assets/media/landing/benefit6.png",
      en: {
        title: "Adaptation to Modern Trends",
        content:
          "In an era where digital and fast-paced services are valued, implementing this system positions the company as adaptable to modern trends and customer preferences.",
      },
      ar: {
        title: "التكيف مع التوجهات الحديثة",
        content:
          "في عصر يتم فيه تقدير الخدمات الرقمية والسريعة، فإن تطبيق هذا النظام يظهر مدى مرونة الشركة في التكيف مع التوجهات الحديثة بما يتناسب مع العملاء.",
      },
    },
  ];

  constructor(
    private translationService: TranslationService,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    this.currentLang = JSON.parse(localStorage.getItem('defaultLanguage'));
    // console.log("Current Lang: ", this.currentLang);
  }
  ngOnInit(): void {
    this.selectedBenifit = this.benefits.find((_item) => _item.active);
    // this.currentLang = this.translationService.getSelectedLanguage();
  }
  ngAfterViewInit() {
    this.entries = this._document.querySelectorAll(".lnd_animate");
    // console.log("Entries: ", entries[0]);
  }

  @HostListener("window:scroll")
  checkScroll() {
    const threshold = 0.3; // how much % of the element is in view
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.log("Entries: ", entry);
            setTimeout(() => {
              entry.target.classList.add("show_animation");
            }, 300);
            // run your animation code here
            observer.disconnect(); // disconnect if you want to stop observing else it will rerun every time its back in view. Just make sure you disconnect in ngOnDestroy instead
          }
        });
      },
      { threshold }
    );
    // entries(elm=>
    this.entries.forEach((elm) => observer.observe(elm));
  }

  toggleBenefit(i: number) {
    this.selectedBenifit = null;

    let items = this.benefits.map((_item) => {
      _item.active = false;
      return _item;
    });

    this.benefits[i].active = true;
    this.selectedBenifit = this.benefits[i];
  }
}
