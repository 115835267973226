  import { Component, OnDestroy, OnInit } from "@angular/core";
  import {
    animate,
    state,
    style,
    transition,
    trigger,
  } from "@angular/animations";
  
  @Component({
    selector: "lnd-header",
    templateUrl: "./lnd-header.component.html",
    styleUrls: ["./lnd-header.component.scss"],
    animations: [
      trigger("smoothCollapse", [
        state(
          "initial",
          style({
            height: "0",
            overflow: "hidden",
            visibility: "hidden",
          })
        ),
        state(
          "final",
          style({
              overflow: "hidden",
          })
        ),
        transition("initial<=>final", animate("300ms")),
        //   transition(':enter', [
        //     style({ opacity: 0 }),
        //     animate('100ms', style({ opacity: 1 })),
        //   ]),
        //   transition(':leave', [
        //     animate('100ms', style({ opacity: 0 }))
        //   ])
      ]),
    ],    
  })

  export class lndHeaderComponent implements OnInit {
    isCollapsed = true;
    ngOnInit(): void {}
  }