// Angular
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService, currentUser, Logout} from "../../../auth";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../reducers";
import {MatSnackBar} from "@angular/material/snack-bar";

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
    constructor(public authService: AuthService, private snackBar: MatSnackBar) {

    }

    // intercept request and add token
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        // tslint:disable-next-line:no-debugger
        // modify request
        // if(localStorage.getItem('mob') && localStorage.getItem('tok')) {
        //     request = request.clone({
        //         setHeaders: {
        //             // token: localStorage.getItem('tok'),
        //             // mobile: localStorage.getItem('mob')
        //         }
        //     });
        // }

        // console.log('----request----');
        // console.log(request);
        // console.log('--- end of request---');

        return next.handle(request).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                        // console.log('all looks good');
                        // http response status code
                        // console.log(event.status);
                        if (event.body.statusCode == 401){
                            this.authService.logout();
                            this.snackBar.open('Session Expired, Please Login Again!', null, {
                                duration: 7000,
                            });
                        }
                    }
                },
                // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiaHVzc2FpbiBlYnJhaGltIiwiaWQiOiI0ZWIyMWZkMDI0IiwiaWF0IjoxNTg1NDc0ODgxfQ.s53l0nDYDPJT0JluGxj_hWcarxTdGxxhdg1ndj8RSps
                error => {
                    // http response status code
                    // console.log('----response----');
                    // console.error('status code:');
                    // tslint:disable-next-line:no-debugger
                    console.error(error.status);
                    console.error(error.message);
                    // console.log('--- end of response---');
                }
            )
        );
    }
}
