<!-- begin:: Footer -->
<div class="footer border-top border-top-light-dark py-4 d-flex flex-lg-column " id="kt_footer" [ngClass]="footerClasses">
	<div [ngClass]="footerContainerClasses" class=" d-flex flex-column flex-md-row align-items-xl-center justify-content-between container">
		<div class="nav nav-dark order-1 order-md-1">
			<a href="https://skiplino.com" target="_blank">
				<img  style="width: 120px;height: 42px;" src="./assets/media/skiplino/footer-skiplino-logo.svg">
			</a>
		</div>

		<!--begin::Nav-->
		<div class="nav nav-dark order-2 order-md-1">
			<a href="https://skiplino.com/terms/" target="_blank" class="nav-link pr-3 pl-0">{{'PAGES.GENERAL.TERMS_AND_CONDITIONS' | translate}}</a>
			<a href="https://skiplino.com/policy/" target="_blank" class="nav-link px-3">{{'PAGES.GENERAL.PRIVACY_POLICY' | translate}}</a>
		</div>

		<!--begin::Copyright-->
		<div class="text-gray order-3 order-md-2">
			{{'PAGES.GENERAL.RIGHTS_RESERVED' | translate}} @ {{today | date:'yyyy'}} {{'PAGES.GENERAL.SKIPLINO_FOOTER' | translate}}
		</div>
		<!--end::Copyright-->
	</div>
</div>
<!-- end:: Footer -->
