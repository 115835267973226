// Angular
import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
// Layout
import {HtmlClassService} from '../../html-class.service';
import {LayoutConfigService, ToggleOptions} from '../../../../core/_base/layout';
import {currentUser, Logout, User} from "../../../../core/auth";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../core/reducers";
import {WINDOW} from "../../../../core/_base/crud/providers/window.provider";
import {Observable} from "rxjs";
import {CompanyService} from "../../../../core/_base/crud/services/company/company.service";
import {WebBookingService} from "../../../../resolvers/webBooking/web-booking.service";

@Component({
    selector: 'kt-header-mobile',
    templateUrl: './header-mobile.component.html',
    styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnInit {
    // Public properties

    user$: Observable<User>;
    headerLogo = '';
    asideSelfDisplay = true;
    headerMenuSelfDisplay = true;
    headerMobileClasses = '';
    logo;
    company;
    /**
     * Component constructor
     *
     * @param layoutConfigService: LayoutConfigService
     */
    constructor(private layoutConfigService: LayoutConfigService,
                private uiService: HtmlClassService,
                public companyService: CompanyService,
                @Inject(WINDOW) private window: Window,
                private store: Store<AppState>,
                public cdr: ChangeDetectorRef,
                private webBookingService: WebBookingService) {
      this.user$ = this.store.pipe(select(currentUser));
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.headerMobileClasses = this.uiService.getClasses('header_mobile', true).toString();
        this.headerLogo = this.getLogoUrl();
        this.asideSelfDisplay = this.layoutConfigService.getConfig('aside.self.display');
        this.headerMenuSelfDisplay = this.layoutConfigService.getConfig('header.menu.self.display');
        this.logo = this.webBookingService.getCompany()?.logo;
        this.cdr.markForCheck();
        this.company = this.webBookingService.getCompany();
    }

    getLogoUrl() {
        const headerSelfTheme = this.layoutConfigService.getConfig('header.self.theme') || '';
        const brandSelfTheme = this.layoutConfigService.getConfig('brand.self.theme') || '';
        let result = 'logo-light.png';
        if (!this.asideSelfDisplay) {
            if (headerSelfTheme === 'light') {
                result = 'logo-dark.png';
            }
        } else {
            if (brandSelfTheme === 'light') {
                result = 'logo-dark.png';
            }
        }
        return `./assets/media/logos/${result}`;
    }

    /**
     * Log out
     */
    logout() {
        this.store.dispatch(new Logout());
    }
}
