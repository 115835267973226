import { Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "lnd-above",
  templateUrl: "./lnd-above.component.html",
  styleUrls: ["./lnd-above.component.scss"],
})
export class lndAboveComponent implements OnInit {
  constructor(@Inject(DOCUMENT) private _document: HTMLDocument) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    let entries: NodeListOf<Element> | undefined = this._document.querySelectorAll(".lnd_animate");
    // console.log("Entries: ", entries[0]);

    const threshold = 0.2; // how much % of the element is in view
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.log("Entries: ", entry);
            setTimeout(() => {
              entry.target.classList.add('show_animation');
            }, 300);
            // run your animation code here
            observer.disconnect(); // disconnect if you want to stop observing else it will rerun every time its back in view. Just make sure you disconnect in ngOnDestroy instead
          }
        });
      },
      { threshold }
    );
    // entries(elm=>
    entries.forEach(elm=>observer.observe(elm))
    
  }
}
