 <!-- begin: Header -->
<div ktHeader #ktHeader class="header container header-padding-border"
     [ngStyle]="{'backgroundColor': company?.webbooking?.options?.headerBackgroundColor || '', 'box-shadow': '0 0 15px ' + company?.webbooking?.options?.headerShadowColor || company?.webbooking?.options?.headerBackgroundColor || ''}" [ngClass]="headerClasses" id="kt_header">
  <!--begin::Container-->
  <div [ngClass]="headerContainerClasses" class="d-flex align-items-center justify-content-between">

    <ng-container *ngIf="(loader.progress$|async) > 0">
      <ngb-progressbar class="loading-bar" [value]="loader.progress$|async" height="3px"></ngb-progressbar>
    </ng-container>

    <!-- begin: Header Menu -->
    <ng-container *ngIf="true">
      <kt-menu-horizontal
        [headerLogo]="headerLogo"
        [headerMenuSelfDisplay]="headerMenuSelfDisplay"
        [headerMenuClasses]="headerMenuClasses"
      ></kt-menu-horizontal>
    </ng-container>
    <!-- end: Header Menu -->

    <!-- begin:: Header Topbar -->
    <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
    <!--begin::Topbar-->
    <div  id="my-content">
      <kt-topbar class="topbar"></kt-topbar>
    </div>

    <!--end::Topbar-->
    <!-- end:: Header Topbar -->
  </div>
  <!--end::Container-->
</div>
<!-- end: Header -->
