// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
// Auth
import { AuthGuard, BookingGuard } from "./core/auth";
import {LandingComponent} from "./views/pages/landing/landing.component";

const routes: Routes = [
  {
    path: 'error',
    loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule)
  },
  {
    path: 'ticket',
    component: BaseComponent,
    children:[
      {
        path: '',
        loadChildren: () => import('./views/pages/ticket/ticket.module').then(m => m.TicketModule),
      }
    ]
  },
  {
    path: 'call',
    loadChildren: () => import('./views/pages/call/call.module').then(m => m.CallModule),
  },
  {
    path: 'qrBooking',
    loadChildren: () => import('./views/pages/qrBooking/qrBooking.module').then(m => m.QrBookingModule),
  },
  {
    path: 'landing',
    // component: LandingComponent,
    loadChildren: ()=> import('./views/pages/landing/landing.module').then(m=>m.LandingModule)
  },
  {
    path: '',
    component: BaseComponent,
    // canActivate: [ContentGuard],
    children: [
      {
        path: 'auth',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'branches',
        loadChildren: () => import('./views/pages/branch/branch.module').then(m => m.BranchModule),
      },
      {
        path: 'booking',
        // canActivate: [BookingGuard],
        loadChildren: () => import('./views/pages/booking/booking.module').then(m => m.BookingModule),
      },
      { path: '', redirectTo: 'branches', pathMatch: 'full' },
      { path: '**', redirectTo: 'branches', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
