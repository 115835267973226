import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'kt-prebooking-popup',
  templateUrl: './prebooking-popup.component.html',
  styleUrls: ['./prebooking-popup.component.scss']
})
export class PrebookingPopupComponent implements OnInit {
  currentLanguage;
  constructor(public activeModal: NgbActiveModal) {
    this.currentLanguage = JSON.parse(localStorage.getItem('defaultLanguage'));
  }
  @Input() options;
  ngOnInit(): void {
  }

  close() {
    this.activeModal.dismiss();
  }

  continue() {
    this.activeModal.close('data');
  }
}
