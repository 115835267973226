// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HttpUtilsService} from "../..";
import {environment} from "../../../../../../environments/environment";
import {PrebookingPopupComponent} from "../../../../../views/partials/content/skiplino/prebooking-popup/prebooking-popup.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

/**
 * Auth routes enum
 */
enum BranchServiceRoutes {
    get = 'branches/get',
    popular = 'branches/get-popular-times'
}

@Injectable({
    providedIn: 'root'
})

export class BranchService {
    // Public Properties
    public baseURL;

    /**
     * Constructor component
     *
     * @param http
     * @param httpUtils
     */
    constructor(public router: Router, private http: HttpClient, public httpUtils: HttpUtilsService, private modalService: NgbModal, private translate: TranslateService,) {
        this.baseURL = environment.httpBaseURL;
    }

    get(data) {
        return this.httpUtils.getObs(this.baseURL + BranchServiceRoutes.get, data);
    }

    getPopular(data) {
        return this.httpUtils.get(this.baseURL + BranchServiceRoutes.popular, data);
    }

    getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resp => {
                    resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
                },
                error => {
                    // if (error.code == 1) {
                    //     let popup = this.modalService.open(PrebookingPopupComponent, {
                    //         centered: true,
                    //     });
                    //     popup.componentInstance.options = {
                    //         ...{
                    //             text: 'You have decided not to share your position from the browser. If you are using any iOS device ' +
                    //                 'Please enable location from settings -> chrome -> location. Then refresh the page to continue',
                    //             cancelButton: {active: true, text: 'Close'},
                    //             showChromeLocationSettings: true
                    //         }, serviceName: 'PERMISSION DENIED'
                    //     };
                    // } else
                    if (error.code == 2) {
                        let popup = this.modalService.open(PrebookingPopupComponent, {
                            centered: true,
                        });
                        popup.componentInstance.options = {
                            ...{
                                text: this.translate.instant('PAGES.GENERAL.NETWORK_ERR_MSG'),
                                cancelButton: {active: false, text: this.translate.instant('PAGES.GENERAL.CLOSE')}
                            }, serviceName: this.translate.instant('PAGES.GENERAL.NETWORK_ERR')
                        };
                    } else if (error.code == 3) {
                        let popup = this.modalService.open(PrebookingPopupComponent, {
                            centered: true,
                        });
                        popup.componentInstance.options = {
                            ...{
                                text: this.translate.instant('PAGES.GENERAL.NETWORK_ERR_MSG2'),
                                cancelButton: {active: true, text: this.translate.instant('PAGES.GENERAL.CLOSE')}
                            }, serviceName: this.translate.instant('PAGES.GENERAL.NETWORK_ERR')
                        };
                    }
                    // reject(error);
                    // this.router.navigateByUrl('auth/enable-location');
                    resolve({lng: 0, lat: 0});
                });
        });

    }

}
