<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
	<div class="login login-3 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid" id="kt_login">
		<!--begin::Aside-->
		<div *ngIf="false" class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
			 [ngStyle]="{'background-image':'url(' + ('' || 'https://media.gettyimages.com/videos/abstract-animation-background-video-id495232316?s=640x640')  + ')'}">
			<!--begin: Aside Container-->
			<div class="d-flex flex-row-fluid flex-column justify-content-between">
				<!--begin: Aside header-->
				<a href="#" class="flex-column-auto mt-5">
					<img src="" class="max-h-70px" />
					<h3 class="font-size-h1 mb-5 mt-2 text-white"></h3>
				</a>
				<!--end: Aside header-->

				<!--begin: Aside content-->
				<div class="flex-column-fluid d-flex flex-column justify-content-center">
<!--					<h3 class="font-size-h1 mb-5 text-white">KFH</h3>-->
					<p class="font-weight-lighter text-white opacity-80">
<!--						The ultimate Bootstrap, Angular 8, React & VueJS admin theme framework for next generation web-->
<!--						apps.-->
					</p>
				</div>
				<!--end: Aside content-->

				<!--begin: Aside footer for desktop-->
				<div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
					<div class="opacity-70 font-weight-bold	text-white mt-1">
						&copy; 2022 Skiplino
					</div>
					<div class="d-flex">
<!--						<a href="#" class="text-white">Privacy</a>-->
<!--						<a href="#" class="text-white ml-10">Legal</a>-->
<!--						<a href="#" class="text-white ml-10">Contact</a>-->
					</div>
				</div>
				<!--end: Aside footer for desktop-->
			</div>

		</div>
		<!--begin::Aside-->

		<!--begin::Content-->
		<div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
			<!--begin::Content header-->
<!--			<div-->
<!--				class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"-->
<!--			>-->
<!--				<span class="font-weight-bold text-dark-50">-->
<!--					Don't have an account yet?-->
<!--				</span>-->
<!--				<a-->
<!--					href="javascript:;"-->
<!--					routerLink="/auth/register"-->
<!--					class="font-weight-bold ml-2"-->
<!--					id="kt_login_signup"-->
<!--				>{{ "AUTH.GENERAL.SIGNUP_BUTTON" | translate }}</a>-->
<!--			</div>-->
			<!--end::Content header-->
			
			<router-outlet></router-outlet>
			<!--begin::Content footer for mobile-->
<!--			<div-->
<!--				class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">-->
<!--				<div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">-->
<!--					&copy; 2020 Skiplino-->
<!--				</div>-->
<!--				<div class="d-flex order-1 order-sm-2 my-2">-->
<!--&lt;!&ndash;					<a href="#" class="text-dark-75 text-hover-primary">Privacy</a>&ndash;&gt;-->
<!--&lt;!&ndash;					<a href="#" class="text-dark-75 text-hover-primary ml-4">Legal</a>&ndash;&gt;-->
<!--&lt;!&ndash;					<a href="#" class="text-dark-75 text-hover-primary ml-4">Contact</a>&ndash;&gt;-->
<!--				</div>-->
<!--			</div>-->
			<!--end::Content footer for mobile-->
		</div>
		<!--end::Content-->
	</div>
</div>
