import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {User} from '../_models/user.model';
import {Permission} from '../_models/permission.model';
import {Role} from '../_models/role.model';
import {catchError} from 'rxjs/operators';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {Logout} from "../_actions/auth.actions";

const API_USERS_URL = 'api/users';
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'api/roles';

/**
 * Auth routes enum
 */
enum AuthServiceRoutes {
    register = 'customers/register-v3',
    updateVerificationMethod = 'customers/update-verify-method',
    verifyNumber = 'customers/verify-v3',
    fetch = 'customers/get-info'
}

@Injectable()
export class AuthService {
    // Public Properties
    public baseURL;

    constructor(private http: HttpClient, public httpUtils: HttpUtilsService, private store: Store<AppState>) {
        this.baseURL = environment.httpBaseURL;
    }

    // Authentication/Authorization
    login(email: string, password: string): Observable<User> {
        return this.http.post<User>(API_USERS_URL, {email, password});
    }

    logout() {
        this.store.dispatch(new Logout());
    }

    getUserByToken(): Observable<User> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken);
        return this.http.get<User>(API_USERS_URL, {headers: httpHeaders});
    }

    register(data , token?:string): Promise<any> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        httpHeaders = httpHeaders.set('platform', 'web');
        httpHeaders = httpHeaders.set('recaptcha', token);
        console.log('httpHeaders' , httpHeaders)
        return this.httpUtils.post(this.baseURL + AuthServiceRoutes.register, data, {headers: httpHeaders});
    }

    updateVerificationMethod(data): Promise<any> {
        let headers = this.getHeadersIfExists();
        data = {...data, ...headers};
        return this.httpUtils.post(this.baseURL + AuthServiceRoutes.updateVerificationMethod, data);
    }

    verifyNumber(data): Promise<any> {
        let headers = {};
        return this.httpUtils.post(this.baseURL + AuthServiceRoutes.verifyNumber, data, {headers: headers});
    }

    fetch(): Promise<any> {
        let headers = this.getHeadersIfExists();
        return this.httpUtils.get(this.baseURL + AuthServiceRoutes.fetch, headers);
    }


    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
        return this.http.get(API_USERS_URL + '/forgot?=' + email)
            .pipe(catchError(this.handleError('forgot-password', []))
            );
    }


    getAllUsers(): Observable<User[]> {
        return this.http.get<User[]>(API_USERS_URL);
    }

    getUserById(userId: number): Observable<User> {
        return this.http.get<User>(API_USERS_URL + `/${userId}`);
    }


    // DELETE => delete the user from the server
    deleteUser(userId: number) {
        const url = `${API_USERS_URL}/${userId}`;
        return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
    // tslint:disable-next-line
    updateUser(_user: User): Observable<any> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_USERS_URL, _user, {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: User): Observable<User> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_USERS_URL, user, {headers: httpHeaders});
    }

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, {headers: httpHeaders});
    }

    // Permission
    getAllPermissions(): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }

    getRoleById(roleId: number): Observable<Role> {
        return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
    createRole(role: Role): Observable<Role> {
        // Note: Add headers if needed (tokens/bearer)
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<Role>(API_ROLES_URL, role, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the role on the server
    updateRole(role: Role): Observable<any> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_ROLES_URL, role, {headers: httpHeaders});
    }

    // DELETE => delete the role from the server
    deleteRole(roleId: number): Observable<Role> {
        const url = `${API_ROLES_URL}/${roleId}`;
        return this.http.delete<Role>(url);
    }

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<QueryResultsModel>(API_ROLES_URL + '/findRoles', queryParams, {headers: httpHeaders});
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
      *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    /**
     * Get user info by IP.
     *
     * @param data
     */
    getIpapiInfo(ip: string): Observable<any> {
        return this.http.get(`https://api.ipapi.com/${ip}?access_key=84e9cc7f7fd4dd5080f307c82dab8116`);
    }

    /**
     * Get user info by IP.
     *
     * @param data
     */
    getFreegioipInfo(): Observable<any> {
        return this.http.get(`https://freegeoip.app/json/`);
    }

    /**
     * Get user info from ipapi => 'https://ipapi.co/'
     */
    getIP(): Observable<any> {
        return this.http.get(`https://api.ipify.org?format=json&callback=?`);
    }

    /**
     * Get token and (mobile or email) if user logged in.
     */
    getHeadersIfExists() {
        let data = {};
        if (localStorage.getItem('tok') && (localStorage.getItem('mob') || localStorage.getItem('email'))) {
            data['_token'] = localStorage.getItem('tok');
            localStorage.getItem('mob') ? data['_mobile'] = localStorage.getItem('mob').substr(1) : data['_authEmail'] = localStorage.getItem('email');
        }
        return data;
    }
}
