<div class="modal-header" #popup>
    <h4 class="modal-title" [dir]="currentLanguage?.code === 'ar' ? 'rtl' : 'ltr'" *ngIf="options?.serviceName">{{options?.serviceName}}</h4>
    <button type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h5 [dir]="currentLanguage?.code === 'ar' ? 'rtl' : 'ltr'">
        {{options?.text}}
    </h5>
    <div class="mt-16 text-center" *ngIf="options?.downloadApp">
        <div class="row">
            <div class="col">
                <a href="https://apps.apple.com/bh/app/skiplino/id1065862072" target="_blank" class="mr-4"
                   [inlineSVG]="'./assets/media/skiplino/ticket/apple.svg'"></a>
            </div>
            <div class="col">
                <a href="https://play.google.com/store/apps/details?id=co.lvlz.skiplino&hl=en" target="_blank"
                   [inlineSVG]="'./assets/media/skiplino/ticket/google.svg'"></a>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" *ngIf="options?.cancelButton?.active" (click)="close()">{{options?.cancelButton?.text}}</button>
    <button type="button" class="btn btn-primary" *ngIf="(options?.bookButton?.active) || (!options?.cancelButton?.active && !options?.bookButton?.active)" (click)="continue()">{{options?.bookButton?.text}}</button>
</div>
