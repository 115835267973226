<!-- begin:: Header Mobile -->
<div id="kt_header_mobile"
     [ngStyle]="{'backgroundColor': company?.webbooking?.options?.headerBackgroundColor || '', 'box-shadow': '0 0 15px ' + company?.webbooking?.options?.headerShadowColor || company?.webbooking?.options?.headerBackgroundColor || ''}"
     class="header-mobile align-items-center container"
     [ngClass]="headerMobileClasses">
  <!--begin::Logo-->
<!--  <a routerLink="/">-->
<!--	  <img alt="logo" [attr.src]="headerLogo"/>-->
<!--  </a>-->
    <a routerLink="/">
  	  <img alt="logo" width="40px" [src]="logo" style="border-radius: 5px"/>
<!--      <div style="display: inline-block" class="m-3">KFH</div>-->
    </a>
  <!--end::Logo-->

  <!--begin::Toolbar-->
  <div class="d-flex align-items-center">
    <ng-container *ngIf="asideSelfDisplay">
      <!--begin::Aside Mobile Toggle-->
      <button class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
    </ng-container>

<!--    <ng-container *ngIf="headerMenuSelfDisplay">-->
<!--      &lt;!&ndash;begin::Header Menu Mobile Toggle&ndash;&gt;-->
<!--      <button class="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">-->
<!--        <span></span>-->
<!--      </button>-->
<!--      &lt;!&ndash;end::Header Menu Mobile Toggle&ndash;&gt;-->
<!--    </ng-container>-->

    <!--begin::Topbar Mobile Toggle-->
<!--    <button ktToggle [options]="toggleOptions" class="btn btn-hover-text-primary p-0 ml-2" id="kt_header_mobile_topbar_toggle">-->
<!--      <span [inlineSVG]="'./assets/media/svg/icons/General/User.svg'" class="svg-icon svg-icon-xl"></span>-->
<!--    </button>-->
        <kt-language-selector></kt-language-selector>
        <button class="btn btn-hover-text-primary p-0 ml-2" *ngIf="user$ | async as user">
            <div *ngIf="false" ngbDropdown class="d-inline-block" placement="bottom-right">
                <button class="btn dropdown-toggle font-weight-bolder" id="dropdownBasic1"
                 [ngStyle]="{'color': company?.webbooking?.options?.HeaderAuthMethodColor?.text || '#212121'}" ngbDropdownToggle>
                    {{user.verificationMethod !== 'email' ? user.mobile : user.authEmail}} &nbsp;
                    <i class="fa fas fa-angle-down icon-lg" [ngStyle]="{'color': company?.webbooking?.options?.HeaderAuthMethodColor?.icon || '#212121'}"></i></button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button  class="dropdown-item" (click)="logout()">
                        <span [inlineSVG]="'./assets/media/svg/icons/Navigation/Sign-out.svg'" class="svg-icon svg-icon-xl"></span>
                        <div class="font-weight-bold" style="line-height: 25px;">Logout</div>
                    </button>

                </div>
            </div>

            <div class="d-inline-block">
              <button [matMenuTriggerFor]="userMobileMenu" class="btn dropdown-toggle font-weight-bolder" id="dropdownBasic1" [ngStyle]="{'color': company?.webbooking?.options?.HeaderAuthMethodColor?.text || '#212121'}">
                 {{user.verificationMethod !== 'email' ? user.mobile : user.authEmail}} &nbsp;
                 <i class="fa fas fa-angle-down icon-lg" [ngStyle]="{'color': company?.webbooking?.options?.HeaderAuthMethodColor?.icon || '#212121'}"></i>
              </button>
              <mat-menu #userMobileMenu="matMenu">              
                <div>
                    <button  class="dropdown-item" (click)="logout()">
                        <div class="span" style="display: flex; align-items: center; gap: 12px;">
                          <span [inlineSVG]="'./assets/media/svg/icons/Navigation/Sign-out.svg'" class="svg-icon svg-icon-xl"></span>
                          <span class="font-weight-bold" style="line-height: 25px;">{{'PAGES.GENERAL.LOGOUT' | translate}}</span>
                        </div>
                    </button>
                </div>              
              </mat-menu>
            </div>

        </button>
      <button class="btn btn-hover-text-primary p-0 ml-2" routerLink="/auth" *ngIf="!(user$ | async)">
          <button type="button" class="btn btn-primary btn-sm mr-2 font-weight-boldest text-white rounded-xl">{{'PAGES.GENERAL.LOGIN' | translate}}</button>
      </button>
    <!--end::Topbar Mobile Toggle-->
  </div>
  <!--end::Toolbar-->
</div>
<!-- end:: Header Mobile -->
